import React,{ useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import OtpInput from "react-otp-input";
import { useDispatch} from "react-redux";
import {registerAuth} from "../../Redux/Features/authReducer/authSlice";

function Otp(props) {
  const { visible, setVisible, otp, setOtp, submitUser, data,buttonVisible  } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    if (!visible) {
      setOtp(""); // Reset otp state when dialog is closed
    }
  }, [visible]);

  const reSendotp=()=>{
    if(data?.email != ""){
      dispatch(registerAuth({ email: data.email }))
    }
  }
  const footerContent = (
    <>
      <p className="text-center mb-3">
        Did'nt get the code?{" "}
        <Button className="text-dark button-outline-none" label="Resend code" text onClick={() => reSendotp()} />
      </p>
      <button
        className="btn btn-lg button-navy rounded-pill w-100 mt-2"
        label="Submit"
        disabled={buttonVisible}
        onClick={() => submitUser()}
      >
        {buttonVisible ? (
          <span
            className="spinner-border spinner-border-sm me-2 text-dark"
            role="status"
            aria-hidden="true"
          ></span>
        ) : (
          "Submit"
        )}
      </button>
    </>
  );
  const heading = (
    <div className="mt-4">
      <h4 className="exMedium text-center">Two-Factor Authentication</h4>
      <p className="exLight color-muted fs-14 mt-3 text-center mb-0">
        A verification code has been sent to your email and phone number. This
        code will be valid for 15 minutes.{" "}
      </p>
    </div>
  );
  return (
    <Dialog
      className="twoFactorPopup p-4 background-white rounded-17 mx-3"
      header={heading}
      visible={visible}
      onHide={() => {
        if (!visible) return;
        setVisible(false);
      }}
      footer={footerContent}
    >
      <OtpInput
        value={otp}
        onChange={setOtp}
        numInputs={6}
        renderInput={(props) => <input {...props} />}
      />
    </Dialog>
  );
}
export default Otp;
