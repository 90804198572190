import React,{useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { googleLogin} from "../../../Redux/Features/authReducer/authSlice";

const GoogleLoginButton = () => {
    const dispatch = useDispatch();
  const {googleLoginData} = useSelector((state) => state.Auth);

useEffect(() => {
    dispatch(googleLogin())
  }, []);

  const handleLoginSuccess = () => {
    window.location.href = googleLoginData?.authUrl;
  };
    return (
        <>
        <button className="btn btn-lg btn-outline-purple rounded-0 form-control text-white py-3" onClick={handleLoginSuccess}>SIGN IN VIA GOOGLE
                    <img src={process.env.PUBLIC_URL + "/Assets/images/icons/google-icon.png"} alt="google" className="google-icon   ms-3 mb-1" />
                  </button>
                  <p className="text-secondary my-4"> - - - - - - - - - - <span className="text-white">OR</span> - - - - - - - - - - </p>
        </>
    );
};

export default GoogleLoginButton;
