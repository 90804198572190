import React from "react";
import { useNavigate } from "react-router";
import { BACK_TO_PAGE} from "../../Routes/Constant";

const PageNotFound = () => {
  const navigate=useNavigate();
  
  const backpage=()=>{
    navigate(BACK_TO_PAGE);
  }
  return (
    <>
      <div id="Page_notFound">
        <div id="wrapper">
          <section id="content-wrapper" className="vh-100">
            <div class="bg_404">
              <div class="msg_404  text-center mb-4 ">
                <p class="exMonoBold display-2 text-navy mb-4">404</p>
                <h4 class="exMedium mb-4">Page Not Found.</h4>
                <p className="lh-lg">
                  Sorry, the page you are looking for cannot be found. <br />{" "}
                  Please check the URL or try navigating back.
                </p>
                <button
                  class="btn btn-lg button-navy rounded-pill mt-5"
                  label="Link"
                  link
                  onClick={()=>backpage()}
                >
                  <svg
                    className="me-2"
                    width="28"
                    height="28"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_319_15512"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="28"
                      height="28"
                    >
                      <rect
                        x="20"
                        y="20.5"
                        width="20"
                        height="20"
                        transform="rotate(-180 20 20.5)"
                        fill="white"
                      />
                    </mask>
                    <g mask="url(#mask0_319_15512)">
                      <path
                        d="M8.34211 6.75L9.24211 7.91667L7.91579 9.66667H17.5V11.3333L7.91579 11.3333L9.22632 13.0833L8.32632 14.25L5.5 10.5L8.34211 6.75Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                  Back
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
