import axios from "axios";
import { getToken, getToken2, setSubsId } from "../LocalStorage";
import { HeaderToken } from "../../../Utils/constant/commonFunction";

import {
    BASE_URL,
    SUBSCRIPTION,
    BUY_SUBSCRIPTION,
    CHECK_PAYMENT_STATUS,
    ADD_CUSTOMER_CARD,
    GET_CARD_DETAILS,
    DELETE_STRIPE_PAYMENT_METHOD
} from "../../../Config/Config"

// Fetch subscription details  API
const fetchSubscriptionApi = async () => {
    const response = await axios.get(
        BASE_URL + SUBSCRIPTION,
        HeaderToken(getToken2() ? getToken2() : getToken())
    );
    return response?.data;
};

// Buy subscription API
const buySubscriptionApi = async (data) => {
    const response = await axios.post(
        BASE_URL + BUY_SUBSCRIPTION,
        data,
        HeaderToken(getToken2() ? getToken2() : getToken()),
    );
    if (response?.data?.success) {
        setSubsId(response.data.id)
       }  

    return response?.data;
};

// Buy subscription API
const checkPaymentStatusApi = async (data) => {
    const response = await axios.post(
        BASE_URL + CHECK_PAYMENT_STATUS,
        data,
        HeaderToken(getToken2() ? getToken2() : getToken()),
    );
    return response?.data;
};

// Add Customer Card for Payment

export const addCustomerCardApi = async (data) => {
    try {
        const response = await axios.post(
            BASE_URL + ADD_CUSTOMER_CARD,
            data,
            HeaderToken(getToken2() ? getToken2() : getToken())
        );
        return response?.data;
    } catch (error) {
        return;
    }
};

export const showCustomerCardListAPI = async () => {
    try {
        const response = await axios.get(
            BASE_URL + GET_CARD_DETAILS,
            HeaderToken(getToken2() ? getToken2() : getToken())
        );
        return response?.data;
    } catch (error) {
        return;
    }
};

// Delete Customer Card for Payment

export const deleteStripePaymentMethod = async (data) => {
    try {
        const response = await axios.post(
            BASE_URL + DELETE_STRIPE_PAYMENT_METHOD,
            data,
            HeaderToken(getToken2() ? getToken2() : getToken())
        );
        return response?.data;
    } catch (error) {
        return;
    }
};

const subscriptionService = {
    fetchSubscriptionApi,
    buySubscriptionApi,
    checkPaymentStatusApi,
    deleteStripePaymentMethod,
    showCustomerCardListAPI,
    addCustomerCardApi
}

export default subscriptionService;