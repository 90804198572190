import React from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Header from '../../Layouts/HeaderPage';
import SideBar from '../../Layouts/SideBar';
import AllNotification from '../../Components/AllNotification';
import { markAllReadApi } from '../../Redux/Features/notificationReducer/notificationSlice';
function Notification() {
    const dispatch = useDispatch();
    const { fetchedNotifications } = useSelector((state) => state.Notification);
    const handleMarkAllAsRead = (data) => {
        dispatch(markAllReadApi(data))
    }
    return (
        <>
            <div id="allNotifi_page">
                <Header />
                <div id="wrapper">
                    <SideBar />
                    <section id="content-wrapper">
                        <h2 className=" content-title mb-0 exDemiBold mb-2">All Notifications</h2>
                        {/* <p className="text-navy-light mb-4">
                            Lorem ipsum dolor sit amet, consectetur.
                        </p> */}
                        <AllNotification
                            data={fetchedNotifications?.data || []}
                            handleMarkAllAsRead={handleMarkAllAsRead}
                        />
                    </section>
                </div>
            </div>
        </>
    )
}

export default Notification
