import PropertyResetConfirm from "../../../Components/Dialogs/reset";
import { useResetProperty } from "../../../hooks/mutations/properties";
import ColumnActionsButton from "./button";

const ColumnReset = ({ rowData }) => {

  const primaryIds = [rowData.recordId];
  const resetPropertyMutation = useResetProperty(primaryIds);

  return (
    <ColumnActionsButton
      rowData={rowData}
      icon="pi-undo"
      tooltip="Reset Listing"
      onClick={() => PropertyResetConfirm({ onConfirm: () => resetPropertyMutation.mutate({ primaryIds }) })}
      disabled={resetPropertyMutation.isPending} />
  )
}

export default ColumnReset;