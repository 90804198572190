import axios from "axios";
import { getToken, getToken2 } from "../LocalStorage";
import { HeaderToken } from "../../../Utils/constant/commonFunction";
import { 
    BASE_URL, 
    AUTOMATED_LISTING_DATA,
    REVIEW_SCORE_DATA,
    BOOKING_PACE_MOMENTUM_DATA,
    TOTAL_REVENUE_FOR_MONTH_DATA,
    BOOKING_PACE_MOMENTUM_AIRBNB_DATA,
    TOP_BOOKING_SITES_DATA,
    TOTAL_REVENUE_MONTH_AND_YEAR_DATA,
    AVG_REVIEW_SCORE,
    AUTOMATED_AND_UNAUTOMATED_LISTING_DATA,
    INSERT_USER_SUBSCRIPTION_TYPE,
    USER_DASHBOARD_DATA
} from "../../../Config/Config"


// Fetch automated listing data  API
const automatedListingDataApi = async () => {
    const response = await axios.get(
      BASE_URL + AUTOMATED_LISTING_DATA,
      HeaderToken(getToken2() ? getToken2() : getToken())
    );
    return response?.data;
  };

// Fetch review score data  API
const reviewScoreDataApi = async () => {
    const response = await axios.get(
      BASE_URL + REVIEW_SCORE_DATA,
      HeaderToken(getToken2() ? getToken2() : getToken())
    );
    return response?.data;
  };

// Fetch booking pace momentum data  API
const bookingPaceMomentumDataApi = async () => {
    const response = await axios.get(
      BASE_URL + BOOKING_PACE_MOMENTUM_DATA,
      HeaderToken(getToken2() ? getToken2() : getToken())
    );
    return response?.data;
  };
  
// Fetch total revenue for month data  API
const totalRevenueforMonthDataApi = async () => {
    const response = await axios.get(
      BASE_URL + TOTAL_REVENUE_FOR_MONTH_DATA,
      HeaderToken(getToken2() ? getToken2() : getToken())
    );
    return response?.data;
  };

// Fetch booking pace momentum data  API for airbnb
const bookingPaceMomentumAirbnbDataApi = async () => {
    const response = await axios.get(
      BASE_URL + BOOKING_PACE_MOMENTUM_AIRBNB_DATA,
      HeaderToken(getToken2() ? getToken2() : getToken())
    );
    return response?.data;
  };

// Fetch top booking sites data
const topBookingSitesDataApi = async () => {
    const response = await axios.get(
      BASE_URL + TOP_BOOKING_SITES_DATA,
      HeaderToken(getToken2() ? getToken2() : getToken())
    );
    return response?.data;
  };

// Fetch total revenue data based on the month and year
const totalRevenueMonthAndYearDataApi = async () => {
    const response = await axios.get(
      BASE_URL + TOTAL_REVENUE_MONTH_AND_YEAR_DATA,
      HeaderToken(getToken2() ? getToken2() : getToken())
    );
    return response?.data;
  };

  // Fetch average review score
const getAverageReviewScore = async (data) => {
  const response = await axios.post(
    BASE_URL + AVG_REVIEW_SCORE,
    data,
    HeaderToken(getToken2() ? getToken2() : getToken())
  );
  return response?.data;
};

// Fetch automated and unautomated listing data
const automatedAndUnautomatedListingData = async () => {
  const response = await axios.get(
    BASE_URL + AUTOMATED_AND_UNAUTOMATED_LISTING_DATA,
    HeaderToken(getToken2() ? getToken2() : getToken())
  );
  return response?.data;
};

// Fetch Insert User Subscription Type
const insertUserSubscriptionType = async (data) => {
  const response = await axios.post(
    BASE_URL + INSERT_USER_SUBSCRIPTION_TYPE,
    data,
    HeaderToken(getToken2() ? getToken2() : getToken())
  );
  return response?.data;
};

// Fetch user DashBoard Data
const userDashBoardData = async (data) => {
  const response = await axios.post(
    BASE_URL + USER_DASHBOARD_DATA,
    data,
    HeaderToken(getToken2() ? getToken2() : getToken())
  );
  return response?.data;
};

  const dashboardService = {
    automatedListingDataApi,
    reviewScoreDataApi,
    bookingPaceMomentumDataApi,
    totalRevenueforMonthDataApi,
    bookingPaceMomentumAirbnbDataApi,
    topBookingSitesDataApi,
    totalRevenueMonthAndYearDataApi,
    getAverageReviewScore,
    automatedAndUnautomatedListingData,
    insertUserSubscriptionType,
    userDashBoardData
  }

  export default dashboardService;