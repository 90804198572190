import React, { useState } from 'react';
import { Tab, Nav, Button, Form, Table, InputGroup, FormControl } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
const SubscriptionPlan = () => {
    const [selectedTab, setSelectedTab] = useState('monthly');
    const [price, setPrice] = useState(90);
    const [discount, setDiscount] = useState(20); // Discount in percentage
    const [visible, setVisible] = useState(false);
 
    return (
        <div className="card flex justify-content-center">
            <Button label="Show" icon="pi pi-external-link" onClick={() => setVisible(true)} />
            <Dialog  visible={visible} style={{ width: '25vw' }} onHide={() => { if (!visible) return; setVisible(false); }}>
                <div className="subscription-payment p-4">
                    <h5 style={{marginLeft:"100px"}}>Subscription & Payment</h5>
                    {/* Tab Navigation for Monthly and Yearly */}
                    <Nav variant="pills" className="justify-content-center my-3" activeKey={selectedTab}>
                        <Nav.Item>
                            <Nav.Link eventKey="monthly" onClick={() => setSelectedTab('monthly')}>Monthly</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="yearly" onClick={() => setSelectedTab('yearly')}>Yearly</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    {/* Price Display */}
                    <div className="text-center display-4">${price.toFixed(2)}</div>
                    {/* Currency Selector */}
                    <div className="text-center my-2">
                        <Form.Select className="w-25 mx-auto">
                            <option>$ USD</option>
                            <option>€ EUR</option>
                            <option>£ GBP</option>
                        </Form.Select>
                    </div>
                    {/* Coupon Input and Discount Display */}
                    <InputGroup className="my-3">
                        <FormControl placeholder="Gift Coupon" />
                        <Button variant="outline-secondary">Apply</Button>
                    </InputGroup>
                    <div className="text-center bg-warning text-dark p-2 rounded">
                        <strong>{discount}% OFF</strong> &nbsp; <span>FALL DISCOUNT - Save $25 yearly</span>
                    </div>
                    {/* Features Table */}
                    <Table bordered hover className="mt-4">
                        <thead>
                            <tr>
                                <th>Features</th>
                                <th>Automated</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>AI Listing Optimization on Airbnb <i className='pi pi-info-circle' style={{ color: 'navy' }}></i></td>
                                <td className="text-center"><i className="pi pi-check" style={{ color: 'green' }}></i>
                                </td>
                            </tr>
                            <tr>
                                <td>Dynamic Updates <i className='pi pi-info-circle' style={{ color: 'navy' }}></i></td>
                                <td className="text-center"><i className="pi pi-check" style={{ color: 'green' }}></i>
                                </td>
                            </tr>
                            <tr>
                                <td>PMS Integrations <i className='pi pi-info-circle' style={{ color: 'navy' }}></i></td>
                                <td className="text-center"><i className="pi pi-check" style={{ color: 'green' }}></i>
                                </td>
                            </tr>
                            <tr>
                                <td>Support <i className='pi pi-info-circle' style={{ color: 'navy' }}></i></td>
                                <td className="text-center"><i className="pi pi-check" style={{ color: 'green' }}></i>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    {/* Action Buttons */}
                    <div className="d-flex justify-content-between mt-4">
                        <Button variant="light">Skip</Button>
                        <Button variant="primary">Pay now</Button>
                    </div>
                </div>
            </Dialog>
        </div>

    );
};
export default SubscriptionPlan;