import { createSlice } from '@reduxjs/toolkit'

export const stateSlice = createSlice({
  name: 'state',
  initialState: {
    couponPopup: false,
    proSubscriptionModel: false,
    paymentModalOpen: false,
    subscriptionModalVisible: false,
    guestyModalVisible: false,
    hostawayModalVisible: false,
    automationModalVisible: false,
    userSelectedPlanStatus: false,
    userSelectedSubscriptionListing: {},
    selectedProperties: [],
    freeListingAddButtonLoading: false,
    saveCardButtonLoading: false,
    resetGuestyTokenModalVisible: false,
    newAutoChecked: true,

  },
  reducers: {
    setCouponPopup: (state, action) => {
      state.couponPopup = action.payload
    },
    setProSubscriptionModel: (state, action) => {
      state.proSubscriptionModel = action.payload;
    },
    setSubscriptionModalVisible: (state, action) => {
      state.subscriptionModalVisible = action.payload;
    },
    setGuestyModalVisible: (state, action) => {
      state.guestyModalVisible = action.payload;
    },
    setHostawayModalVisible: (state, action) => {
      state.hostawayModalVisible = action.payload;
    },
    setAutomationModalVisible: (state, action) => {
      state.automationModalVisible = action.payload;
    },
    setPaymentModalOpen: (state) => {
      state.paymentModalOpen = true
    },
    setPaymentModalClose: (state) => {
      state.paymentModalOpen = false
    },
    applyCoupon(state) {
      state.couponPopup = false
      state.paymentModalOpen = true
    },

    setSelectedPlanListing(state, action) {
      state.userSelectedPlanStatus = true;
      state.userSelectedSubscriptionListing = action.payload;
    },
    setSelectedProperties(state, action) {
      state.selectedProperties = action.payload;
    },

    setFreeListingAddButtonLoading: (state, action) => {
      state.freeListingAddButtonLoading = action.payload;
    },
    setSaveCardButtonLoading: (state, action) => {
      state.saveCardButtonLoading = action.payload;
    },
    setResetGuestyTokenModalVisible: (state, action) => {
      state.resetGuestyTokenModalVisible = action.payload;
    },
    setNewAutoChecked: (state, action) => {
      state.newAutoChecked = action.payload;
    },

  },
})

// Action creators are generated for each case reducer function
export const { setCouponPopup, setProSubscriptionModel, setSubscriptionModalVisible, setGuestyModalVisible, setHostawayModalVisible, setAutomationModalVisible, setPaymentModalOpen, setPaymentModalClose, applyCoupon, setSelectedPlanListing,
  setSelectedProperties, setFreeListingAddButtonLoading, setSaveCardButtonLoading, setResetGuestyTokenModalVisible, setNewAutoChecked } = stateSlice.actions;

export default stateSlice.reducer