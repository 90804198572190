import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { successToast, errorToast } from "../../../Utils/constant/commonFunction";
import attractionsService from "./attractionsService";

const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
    attractionsData: "",
    addEventsData: ""
}

/* Add Attractions Data */
export const addAttractionData = createAsyncThunk(
    "addAttractionData",
    async (data, thunkAPI) => {
        try {
            return await attractionsService.addAttractionData(data);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            errorToast(message);
            return thunkAPI.rejectWithValue(message)
        }
    }
)

/* Add Events Data */
export const addEventData = createAsyncThunk(
    "addEventData",
    async (data, thunkAPI) => {
        try {
            return await attractionsService.addEventData(data);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            errorToast(message);
            return thunkAPI.rejectWithValue(message)
        }
    }
)

/*  For Slice */
const attractionsSlice = createSlice({
    name: "attractions",
    initialState,
    reducers: {
        reset: (state) => {
            state.isSuccess = false;
            state.isError = false;
            state.isLoading = false;
            state.message = "";
        },

    },
    extraReducers: (builder) => {
        builder
            // Add Attractions Data
            .addCase(addAttractionData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addAttractionData.fulfilled, (state, { payload }) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.message = payload.message;
                state.attractionsData = payload;
                successToast(payload.message);
            })
            .addCase(addAttractionData.rejected, (state, { payload }) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = payload.message;
            })

             // Add Events Data
             .addCase(addEventData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addEventData.fulfilled, (state, { payload }) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.message = payload.message;
                state.addEventsData = payload;
                successToast(payload.message);
            })
            .addCase(addEventData.rejected, (state, { payload }) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = payload.message;
            })

        },
    });
    
    export const { reset } = attractionsSlice.actions;
    export default attractionsSlice.reducer;