import React from "react";
import Insight from "../../Components/Insights/Insight";
import Header from "../../Layouts/HeaderPage";
import SideBar from "../../Layouts/SideBar";
function Insights() {
    return (
        <div id="connection_page">
            <Header />
            <div id="wrapper">
                <SideBar />
                <section id="content-wrapper">
                <div class="welcome-user">
                    <h2 className=" content-title mb-0 exDemiBold mb-2">Insight</h2>
                    {/* <p className="text-navy-light mb-4">
                        Lorem ipsum dolor sit amet, consectetur.
                    </p> */}
                </div>
                    <Insight />
                </section>
            </div>
        </div>
    )
}

export default Insights;
