import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSubscriptionModalVisible, setAutomationModalVisible } from "../../Redux/Features/stateReducer/stateSlice";
import { HostawayLIstings } from "../../Redux/Features/authReducer/authSlice";
import { setSelectedUserSubscriptionStatus, subscriptionPaymentAPI } from "../../Redux/Features/listReducer/listSlice";
import { setSelectedProperties } from "../../Redux/Features/stateReducer/stateSlice";
import { couponApplied, getAllCoupon } from "../../Redux/Features/couponReducer/couponSlice";
import { Dialog } from "primereact/dialog";
import Swal from "sweetalert2";
import { LISTING } from "../../Routes/Constant";

const SubscriptionPaymentModal = (props) => {
  const navigate = useNavigate();
  const { selectedOrder, selectedOrderrecordId, getAllCouponData, setVisible, setSelectedRanks, setHostingList } = props;

  const dispatch = useDispatch();
  const [toggleMY, settoggleMY] = useState(false);
  const [isApplied, setIsApplied] = useState("");
  const [isshow, setIsShow] = useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [selectedUserSubscriptionType, setSelectedUserSubscriptionType] = useState('yearly');
  const [couponId, setCouponId] = useState("");
  const [remainingAmount, setRemainingAmount] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(null);
  const [subscriptionPrice, setSubscriptionPrice] = useState("");

  const { subscriptionModalVisible, selectedProperties, newAutoChecked } = useSelector((state) => state?.State);
  const { autoUnautoPropertyListingData } = useSelector((state) => state.List);
  const { couponAppliedData } = useSelector((state) => state.Coupon)

  useEffect(() => {
    if (autoUnautoPropertyListingData?.data) {
      setSubscriptionPrice(autoUnautoPropertyListingData?.data?.yearlyPrice)
    }
  }, [autoUnautoPropertyListingData?.data]);

  let timer;
  const handlePayemntMethods = async () => {
    setIsPaymentLoading(true);
    try {

      const createSubscriptionPayload = selectedProperties.reduce((acc, item) => {
        acc.listingIds.push(item.id);
        acc.recordIds.push(item.recordId);
        return acc;
      }, {
        listingIds: selectedOrder.map(id => parseInt(id)),
        recordIds: selectedOrderrecordId,
        isAutomated: newAutoChecked,
        subscriptionType: selectedUserSubscriptionType,
        subscriptionPrice: subscriptionPrice,
        ...(isApplied && {
          promotionCodeId: isApplied,
          couponId: couponId,
        }),
      });

      const response = await dispatch(subscriptionPaymentAPI(createSubscriptionPayload))
        .unwrap()
        .then((result) => {
          setIsPaymentLoading(false);
          dispatch(setSelectedUserSubscriptionStatus(false));
          dispatch(setSelectedProperties([]));
          dispatch(setSubscriptionModalVisible(false));
          dispatch(getAllCoupon());
          // setSelectedUserSubscriptionType("yearly");
          dispatch(setAutomationModalVisible(false));
          settoggleMY(true);
          setIsApplied(null);
          setCouponId(null);
          setVisible(false);
          setIsShow(false);
          setSelectedRanks(0);
          timer = setTimeout(() => {
            dispatch(HostawayLIstings())
              .unwrap()
            navigate(LISTING);
            clearTimeout(timer);
          }, 3000);
        })

    }
    catch (error) {
      setIsPaymentLoading(false);
      dispatch(setSelectedUserSubscriptionStatus(false));
      dispatch(setSelectedProperties([]));
      dispatch(setSubscriptionModalVisible(false));
      dispatch(setAutomationModalVisible(false));
      setIsApplied(null);
      setCouponId(null);
      setVisible(false);
      setIsShow(false);
      setSelectedRanks(0);

      setHostingList(prevList =>
        prevList.map(item => ({ ...item, isSelected: false }))
      );

    }
  };

  const applyCoupon = async (data) => {
    const getData = data;

    try {
      const response = await dispatch(
        couponApplied({
          promotionCodeId: getData?.stripePromotionCodeId,
          subscriptionType: selectedUserSubscriptionType,
          subscriptionPrice: subscriptionPrice,
        })
      ).unwrap();

      if (response?.success) {
        setIsApplied(getData?.stripePromotionCodeId);
        setCouponId(getData?.id);
        setRemainingAmount(response?.data?.remainingAmount);
        setDiscountPrice(response?.data?.discountPrice);
        Swal.fire({
          title: "Success",
          text: response?.message || "An unexpected error occurred.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
          width: "300px",
          customClass: { popup: "small-swal" },
          backdrop: true,
          didOpen: () => {
            const swalPopup = document.querySelector(".swal2-container");
            if (swalPopup) {
              swalPopup.style.zIndex = "2000";
            }
          },
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "Coupon could not be applied. Please try again.",
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
          width: "300px",
          customClass: { popup: "small-swal" },
          backdrop: true,
          didOpen: () => {
            const swalPopup = document.querySelector(".swal2-container");
            if (swalPopup) {
              swalPopup.style.zIndex = "2000";
            }
          },
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error || "An unexpected error occurred.",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
        width: "300px",
        customClass: { popup: "small-swal" },
        backdrop: true,
        didOpen: () => {
          const swalPopup = document.querySelector(".swal2-container");
          if (swalPopup) {
            swalPopup.style.zIndex = "2000";
          }
        },
      });
      console.error(error);
    }
  };

  const removeCupon = async () => {
    Swal.fire({
      title: "Success",
      text: "Coupon removed successfully.",
      icon: "success",
      timer: 1500,
      showConfirmButton: false,
      width: "300px",
      customClass: { popup: "small-swal" },
      backdrop: true,
      didOpen: () => {
        const swalPopup = document.querySelector(".swal2-container");
        if (swalPopup) {
          swalPopup.style.zIndex = "2000";
        }
      },
    });

    setIsApplied(null);
    setCouponId(null);
    setRemainingAmount(null);
    setDiscountPrice(null);

  }

  const resetCopunAmount = () => {
    setIsApplied(null);
    setCouponId(null);
    setRemainingAmount(null);
    setDiscountPrice(null);
  }

  // const PaymentConfirm = (row) => {
  //   confirmDialog({
  //     message: (
  //       <div className="mx-3 my-2 text-center">
  //         <h5 className="exDemiBold fs-2 text-center text-navy my-2">  <i className="pi pi-exclamation-triangle"></i> &nbsp; Confirm Your Payment</h5>
  //         <p className="text-navy-light text-center w-75 m-auto">
  //           Do you want to continue the payment?
  //         </p>
  //       </div>

  //     ),
  //     closable: false, // Add this line to prevent closing the dialog
  //     // dismissableMask: true, // Allow clicking outside to close
  //     defaultFocus: "accept",
  //     accept: () => handlePayemntMethods(),
  //     reject: () => PaymentRejectBullet(),
  //     acceptLabel: "Proceed",
  //     rejectLabel: "Cancel"
  //   });
  // };

  const PaymentRejectBullet = () => { };

  const headerContent = (
    <div className="SmartListing">
      <h4> Subscription & Payment</h4>
    </div>
  )

  return (
    <>
      <Dialog
        header={headerContent}
        visible={subscriptionModalVisible}
        style={{ width: "30vw", borderRadius: "30px" }}
        onHide={() => {
          if (!subscriptionModalVisible) return;
          dispatch(setSubscriptionModalVisible(false));
          setRemainingAmount(null);
          setDiscountPrice(null);

        }}
      >
        <div className="content p-3">

          <div className="my-switch text-center mt-3 mb-4">
            <span
              className={`monthlyTab ${toggleMY ? "active" : ""}`}
              onClick={() => {
                settoggleMY(true);
                setSelectedUserSubscriptionType("monthly");
                setSubscriptionPrice(autoUnautoPropertyListingData?.data?.monthlyPrice);
                resetCopunAmount();
              }}
            >
              Monthly
            </span>
            <span
              className={`monthlyTab ${!toggleMY ? "active" : ""}`}
              onClick={() => {
                settoggleMY(false);
                setSelectedUserSubscriptionType("yearly");
                setSubscriptionPrice(autoUnautoPropertyListingData?.data?.yearlyPrice);
                resetCopunAmount();
              }}
            >
              Yearly
            </span>
          </div>


          <div className="align-items-center d-flex justify-content-center price-currency">
            <div className="price">
              {toggleMY && <h3 className="fw-bold m-0 me-2 text-dark">{remainingAmount !== null ? remainingAmount : autoUnautoPropertyListingData?.data?.monthlyPrice}</h3>}
              {!toggleMY && (
                <h3 className="m-0 me-2 fw-bold text-dark">{remainingAmount !== null ? remainingAmount : autoUnautoPropertyListingData?.data?.yearlyPrice}</h3>
              )}
            </div>
            <div className="currency">
              <select name="" id="" className="usddropdown card-title color-black dash_card card">
                <option value="USD" selected>
                  $ USD
                </option>
                <option value="USD">INR</option>
              </select>
            </div>
          </div>
          {/* <div className="gift-coupan-input mt-5 mb-4">
            <i class="fa-solid fa-gift"></i>
            <input
              type="text"
              className="form-control GiftCouponInput "
              placeholder="Gift coupon"
            />
          </div> */}

          {
            getAllCouponData?.data?.length > 0 && (
              <div className="offer-card d-flex justify-content-between align-items-center">
                {getAllCouponData?.data?.map((item) =>
                  item?.isUsed === false ? (
                    <div key={item.id} className="offer-cardsseprate coupon-item">
                      {item?.amountOff === null ? (
                        <h1 className="mb-0 fw-bold">{item?.percentOff}% OFF</h1>
                      ) : (
                        <h4 className="mb-0 fw-bold">${item?.amountOff} FLAT OFF</h4>
                      )}

                      <div className="gift-container">
                        <p className="m-0 gift-discount">FALL DISCOUNT</p>
                        {
                          discountPrice !== null &&
                          <span className="m-0">Save ${couponAppliedData?.data?.discountPrice}</span>
                        }
                      </div>

                      <div className="applybtn">
                        {isApplied === item?.stripePromotionCodeId ? (
                          <span
                            className="checkIcon"
                            onClick={() => removeCupon()}
                          >
                            <i className="pi pi-check" style={{ color: "#fff" }}></i>
                            &nbsp;
                          </span>
                        ) : (
                          <button className="btn" onClick={() => applyCoupon(item)}>
                            <span>Apply</span>
                          </button>
                        )}
                      </div>
                    </div>
                  ) : null
                )}
              </div>
            )
          }


          <div className="features-table">
            <table class="table dash_card card ">
              <thead>
                <tr>
                  <th className="dash_card card card-title color-black d-flex" style={{ boxShadow: "unset !important" }}>Features</th>
                  <th className="text-center dash_card card card-title color-black" style={{ boxShadow: "unset !important" }}>Automated</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th className="dash_card card card-title color-black" style={{ boxShadow: "unset !important" }}>AI Listing Optimization on Airbnb</th>
                  <td className="text-center dash_card card" style={{ boxShadow: "unset !important" }}>
                    <i className="pi pi-check" style={{ color: 'green' }}></i>
                  </td>
                </tr>
                <tr>
                  <th className="dash_card card card-title color-black" style={{ boxShadow: "unset !important" }}>Dynamic Updates</th>
                  <td className="text-center dash_card card" style={{ boxShadow: "unset !important" }}>
                    <i className="pi pi-check" style={{ color: 'green' }}></i>
                  </td>
                </tr>
                <tr>
                  <th className="dash_card card card-title color-black" style={{ boxShadow: "unset !important" }}>PMS Integrations</th>
                  <td className="text-center dash_card card" style={{ boxShadow: "unset !important" }}>
                    <i className="pi pi-check" style={{ color: 'green' }}></i>

                  </td>
                </tr>
                <tr>
                  <th className="dash_card card card-title color-black" style={{ boxShadow: "unset !important" }}>Support</th>
                  <td className="text-center dash_card card" style={{ boxShadow: "unset !important" }}>
                    <i className="pi pi-check" style={{ color: 'green' }}></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="action-buttons_n">
            <button onClick={() => dispatch(setSubscriptionModalVisible(false))}>Skip</button>

            <button
              onClick={(e) => handlePayemntMethods(e)}
            >
              {isPaymentLoading ? (
                <span
                  className="spinner-border spinner-border-sm me-2 text-dark"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Pay Now"
              )}
            </button>
          </div>

        </div>
      </Dialog>
    </>
  );
}

export default SubscriptionPaymentModal;
