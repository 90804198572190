import React, { useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Alert, Row, Col } from "react-bootstrap";
import { addCustomerCardApi, showCustomerCardListAPI } from "../../Redux/Features/subscriptionReducer/subscriptionSlice";
import { setSaveCardButtonLoading } from "../../Redux/Features/stateReducer/stateSlice";


const SaveCardDetails = ({ setCardVisible }) => {

  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);

  const { saveCardButtonLoading } = useSelector((state) => state.State)

  const handleSubmit = async (event) => {
    dispatch(setSaveCardButtonLoading(true));
    try {
      event.preventDefault();
      if (!stripe || !elements) {
        return;
      }
      const cardElement = elements.getElement(CardNumberElement);
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      const payload = { paymentMethodId: paymentMethod?.id };

      if (error) {
        setError(error.message);
        dispatch(setSaveCardButtonLoading(false));

        return;
      } else {

        await dispatch(addCustomerCardApi(payload))
          .unwrap()
          .then((res) => {
            dispatch(showCustomerCardListAPI(payload))
              .unwrap();
              dispatch(setSaveCardButtonLoading(false));
            setCardVisible(false);
          })
      }

      if (error) {
        setError(error.message);
      } else {
        setCardVisible(false);
        dispatch(setSaveCardButtonLoading(false));
      }
    }
    catch (error) {
      console.log(error);
      dispatch(setSaveCardButtonLoading(false));

    }
  };

  return (
    <>
      <div style={{ padding: "20px" }}>

        <Form
          onSubmit={handleSubmit}
          style={{ lineHeight: "10px", marginTop: "15px" }}
        >
          <Row className="mb-3">
            <Form.Group controlId="formFirstName" as={Col}>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                className="form-control rounded-pill mt-1 color-dark"
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formLastName" as={Col}>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                className="form-control rounded-pill mt-1 color-dark"
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            {" "}
            <Form.Group controlId="formEmail" as={Col}>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control rounded-pill mt-1 color-dark"
                required
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            {" "}
            <Form.Group controlId="formCardNumber" as={Col}>
              <Form.Label>Card Number</Form.Label>
              <CardNumberElement className="form-control rounded-pill mt-1 color-dark" />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            {" "}
            <Form.Group controlId="formExpiryDate" as={Col}>
              <Form.Label>Expiry Date</Form.Label>
              <CardExpiryElement className="form-control rounded-pill mt-1 color-dark" />
            </Form.Group>
            <Form.Group controlId="formCVC" as={Col}>
              <Form.Label>CVC</Form.Label>
              <CardCvcElement className=" form-control rounded-pill mt-1 color-dark" />
            </Form.Group>
          </Row>
          <div className="text-end">
            <Button
              variant="primary"
              type="submit"
              disabled={saveCardButtonLoading}
              className="pt-2"
            >
              {saveCardButtonLoading ? (
                <span
                  className="spinner-border spinner-border-sm me-2 text-dark"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Save Card"
              )}


            </Button>

          </div>
          {error && (
            <Alert variant="danger" className="mt-2">
              {error}
            </Alert>
          )}
        </Form>
      </div>

    </>
  );
};

export default SaveCardDetails;
