import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { latitudeLognitudeOfListings } from "../../Redux/Features/listReducer/listSlice";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css'; // Ensure Leaflet CSS is imported

const MapComponent = (props) => {
  const { recordId } = props;
  const dispatch = useDispatch();

  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [propertyLat, setPropertyLat] = useState([]);

  useEffect(() => {
    dispatch(latitudeLognitudeOfListings());
  }, [dispatch]);

  const { latitudeLognitudeOfListingsData } = useSelector(
    (state) => state.List ?? {}); // Fallback to empty object if state.List is undefined

  useEffect(() => {
    if (latitudeLognitudeOfListingsData?.Data) {
      const filteredData = latitudeLognitudeOfListingsData.Data.filter((item) => item.recordId === recordId);
      setPropertyLat(filteredData);
    }
  }, [latitudeLognitudeOfListingsData?.Data, recordId]);

  useEffect(() => {
    if (!map && propertyLat?.length > 0) { // Check if map hasn't been initialized yet and there's data to display
      const initialMap = L.map('map', {
        center: [propertyLat[0]?.latitude || 50.822388, propertyLat[0]?.longitude || 2.7080566], // Fallback coordinates
        zoom: 7,
      });

      L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(initialMap);
      setMap(initialMap);
    }
  }, [map, propertyLat]);

  useEffect(() => {
    if (map && latitudeLognitudeOfListingsData?.Data) {
      latitudeLognitudeOfListingsData.Data.forEach(item => {

        if (item?.latitude && item?.longitude) {
          const iconPath = item.recordId === recordId
            ? "/Assets/images/location-marker.png"
            : "/Assets/images/location-blue-marker.png";

          const icon = L.icon({
            iconUrl: process.env.PUBLIC_URL + iconPath,
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34]
          });

          // Add marker to the map
          L.marker([item.latitude, item.longitude], { icon }).addTo(map);
        }
      });
    }
  }, [map, latitudeLognitudeOfListingsData?.Data, recordId]);
  return <div id="map" style={{ width: '100%', height: '400px' }}></div>;
};

export default MapComponent;
