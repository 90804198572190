import React from "react";

function DashboardCard(props) {
  return (
    <div className=" col-lg-3 col-md-6 col-sm-6 col-xs-12 ">
      <div
        className="dash_card card border-0 shadow-sm rounded-edge-17 h-100 p-3"
        style={{ background: `${props.bgColor}` }}
      >
        <div class="row g-0">
          <div class="col-4 d-flex align-items-center">
            <div
              className="rounded-circle icon-bg display-center"
              style={{ background: `${props.IconOuter}` }}
            >
              <img src={props.imgURL} alt="" className="img-top img-fluid" />
            </div>
          </div>
          <div class="col-8">
            <div class="card-body ps-2 p-0  mb-2">
              <h4 className="card-title fs-1 fw-bold exBold">
                {props.cardHeading}
              </h4>
              <p className="card-text mb-2 exMedium fs-6">
                {props.cardContent}
              </p>
              <button
                className="btn btn-sm rounded-pill me-2"
                style={{ background: `${props.IconOuter}` }}
              >
                <svg
                  className="me-1"
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_699_2125)">
                    <path
                      d="M2.70763 7.2866C2.89516 7.4756 3.14947 7.58178 3.41463 7.58178C3.6798 7.58178 3.9341 7.4756 4.12163 7.2866L5.91463 5.47894L5.91463 12.1188C5.91463 12.3862 6.01999 12.6426 6.20753 12.8317C6.39506 13.0207 6.64942 13.127 6.91463 13.127C7.17985 13.127 7.4342 13.0207 7.62174 12.8317C7.80927 12.6426 7.91463 12.3862 7.91463 12.1188L7.91463 5.47894L9.70763 7.2866C9.89623 7.47025 10.1488 7.57186 10.411 7.56957C10.6732 7.56727 10.924 7.46124 11.1095 7.27432C11.2949 7.08739 11.4 6.83453 11.4023 6.57019C11.4046 6.30585 11.3038 6.05119 11.1216 5.86104L7.62163 2.33243C7.4341 2.14343 7.1798 2.03725 6.91463 2.03725C6.64947 2.03725 6.39516 2.14343 6.20763 2.33243L2.70763 5.86104C2.52016 6.0501 2.41485 6.30649 2.41485 6.57382C2.41485 6.84115 2.52016 7.09754 2.70763 7.2866Z"
                      fill="#202744"
                    />
                  </g>

                  <defs>
                    <clipPath id="clip0_699_2125">
                      <rect
                        width="12"
                        height="12.0981"
                        fill="white"
                        transform="translate(12.9141 13.6309) rotate(-180)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                {props.percent}
              </button>
              <span className="fs-14 mt-sm-2">{props.duration}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardCard;
