import axios from "axios";
import { getToken, getToken2 } from "../LocalStorage";
import { HeaderToken } from "../../../Utils/constant/commonFunction";

import { BASE_URL, ADD_TOURIST_ATTRACTIONS, ADD_EVENT_DATA } from "../../../Config/Config"

// Add Attraction Data API
const addAttractionData = async (data) => {
    const response = await axios.post(
        BASE_URL + ADD_TOURIST_ATTRACTIONS,
        data,
        HeaderToken(getToken2() ? getToken2() : getToken())
    );
    return response?.data;
};

// Add Event Data API
const addEventData = async (data) => {
    const response = await axios.post(
        BASE_URL + ADD_EVENT_DATA,
        data,
        HeaderToken(getToken2() ? getToken2() : getToken())
    );
    return response?.data;
};

const attractionsService = {
    addAttractionData,
    addEventData
}

export default attractionsService;