import React from "react";
import Header from "../../Layouts/HeaderPage";
import SideBar from "../../Layouts/SideBar";
import PropertyDetail from "../../Components/PropertyDetail";

function PropertyDetails() {
  return (
    <>
      <div className="admin-section">
        <div id="wrapper">
          <SideBar />
          <Header />
          <PropertyDetail />
        </div>
      </div>
    </>
  );
}

export default PropertyDetails;
