import React, { useRef, useState } from 'react'
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from 'primereact/tag';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { ErrorMessage, Field, Form, Formik } from "formik";
import TextError from "../../Components/TextError/index";
import { useDispatch } from 'react-redux';
import { deleteCouponApi, updateCouponApi, getAllCoupon } from '../../Redux/Features/couponReducer/couponSlice';
import { DateFormat } from '../../Utils/constant/commonFunction';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { getUser } from '../../Redux/Features/LocalStorage';
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

const CouponList = (props) => {

    const { getAllCouponData } = props;
    const dispatch = useDispatch();
    const [showEditModal, setShowEditModal] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [editModal, setEditModal] = useState([]);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [selectedRow, setSelectedRow] = useState([]);
    const [selectedCoupon, setSelectedCoupon] = useState([]);
    const [headerText, setHeaderText] = useState('Update Discount Coupon');
    const dateFormat = getUser()?.dateFormat
    const userData = getUser();
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });;

    const toast = useRef(null);
    const buttonEl1 = useRef(null);

    const handleDelete = async (currentRowData) => {
        setSelectedCoupon(currentRowData);
        setLoading(true);
        try {
            await dispatch(deleteCouponApi(currentRowData.id))
                .unwrap()
                .then((result) => {
                    dispatch(getAllCoupon());
                    setLoading(false);
                    setShowEditModal(false);
                })
        } catch (error) {
            setLoading(false);
            setShowEditModal(false);
        }
    };

    const handleEdit = (currentRowData) => {
        setShowEditModal(true);
        setEditModal(currentRowData)
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const handleUpdateCoupon = async (values) => {
        setLoading(true);
        dispatch(updateCouponApi({id:values.id,couponCode:values.couponCode}))
            .then((result) => {
                if (result) {
                    dispatch(getAllCoupon());
                }
                setLoading(false);
                setShowEditModal(false);

            })
            .catch((error) => {
                setLoading(false);
                setShowEditModal(false)
                console.log(error);
            });
    }

    const handleView = async (values) => {
        setHeaderText('Discount Coupon')
        setSelectedRow(values);
        setVisible(true);
    }

    const GlobalDeleteRejectBullet = () => { };

    const CouponDeleteConfirm = (row) => {
        confirmDialog({
            message: (
                <div className="mx-3 my-2 text-center">
                    <h5 className="exDemiBold fs-2 text-center text-navy my-2">
                        <i className="pi pi-info-circle" style={{ color: "rgb(241, 36, 36)" }}></i> &nbsp; Are you sure?</h5>
                    <p className="text-navy-light text-center w-75 m-auto">
                        Do you want to delete this Coupon?
                    </p>
                </div>

            ),
            closable: false, // Add this line to prevent closing the dialog
            defaultFocus: "accept",
            accept: () => handleDelete(row),
            reject: () => GlobalDeleteRejectBullet(),
        });
    };


    const CouponInput = ({ id, label, value }) => (
        <div div className="mb-3" >
            <label htmlFor={id}>{label}</label>
            <input
                className={"form-control rounded-pill mt-1 color-dark"}
                type="text"
                id={id}
                value={value}
                readOnly
            />
        </div >
    );

    const statusBodyTemplate = (varient) => {
        return (
            <Tag
                value={varient?.status === true ? "Active" : varient?.status === false ? "Inactive" : "Unknown"}
                className={
                    varient?.status === true
                        ? "mint-30 color-dark fw-light rounded-pill fs-14 px-3 py-2 exMono"
                        : varient?.status === false
                            ? "red-10 color-dark fw-light rounded-pill fs-14 px-3 py-2 exMono"
                            : "lime-30 color-dark fw-light rounded-pill fs-14 px-3 py-2 exMono"
                }
            ></Tag>
        );
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText
                        value={globalFilterValue}
                        onChange={onGlobalFilterChange}
                        placeholder="Keyword Search"
                    />
                </IconField>
            </div>
        );
    };

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2 mb-3">
            <span className="font-bold white-space-nowrap ">{headerText}</span>
        </div>
    );

    const actionBodyTemplate = (currentRowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-eye"
                    className="fw-bolder"
                    tooltip="View coupon"
                    tooltipOptions={{ position: "top" }}
                    rounded
                    outlined
                    severity="primary"
                    onClick={() => handleView(currentRowData)}
                />
                <Button
                    type="button"
                    icon="pi pi-pencil"
                    className="fw-bolder"
                    tooltip="Edit coupon"
                    tooltipOptions={{ position: "top" }}
                    rounded
                    outlined
                    severity="primary"
                    onClick={() => handleEdit(currentRowData)}
                />

                <Button
                    type="button"
                    icon="pi pi-trash"
                    className="fw-bolder"
                    tooltip="Delete coupon"
                    tooltipOptions={{ position: "top" }}
                    rounded
                    outlined
                    severity="danger"
                    onClick={() => CouponDeleteConfirm(currentRowData)}

                />


            </React.Fragment>
        );
    };

    const header = renderHeader();

    const tableCustomStyles = {
        headCells: {
            style: {

                fontSize: '20px',
                fontWeight: 'bold',
                paddingLeft: '0 8px',
                justifyContent: 'center',
                backgroundColor: '#FFA500'
            },
        },
    }


    return (
        <>
            <Toast ref={toast} />

            <div
                className={`tab-pane fade shadow-sm mt-4 rounded-17 couponList-table ${props?.activeTab === 'couponList' ? 'show active' : ''}`}
                id="couponList"
                role="tabpanel"
                aria-labelledby="couponList-tab"
            >
                <h2>Coupon List</h2>


                <DataTable
                    value={getAllCouponData?.data}
                    dataKey="id"
                    tableStyle={{ minWidth: "50rem" }}
                    size="small"
                    showGridlines
                    removableSort
                    paginator
                    rows={5}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    paginatorTemplate="RowsPerPageDropdown  PrevPageLink CurrentPageReport NextPageLink "
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    filters={filters}
                    filterDisplay="row"
                    globalFilterFields={["couponType", "couponCode", "expiryDate", "minValue"]}
                    emptyMessage="No Coupon found."
                    header={header}
                    customStyles={tableCustomStyles}
                    className='customDataTable'
                >
                    <Column
                        field="couponType"
                        header="Coupon Type"
                        className="exMono"
                        sortable
                        body={(row) => <span>{row?.couponType?.charAt(0)?.toUpperCase() + row?.couponType?.slice(1)}</span>}
                    ></Column>
                    <Column
                        field="couponCode"
                        header="Coupon Code"
                        className="exMono"
                    ></Column>

                    <Column
                        field="minValue"
                        header="Minimum Value"
                        className="exMono"
                        sortable
                    ></Column>
                    <Column
                        field="expiryDate"
                        header="Expiration Date"
                        className="exMono"
                        sortable
                        body={(row) => <span>{DateFormat(row?.expiryDate)}</span>}
                    ></Column>
                    <Column
                        field="status"
                        header="Status"
                        className="exMono"
                        body={statusBodyTemplate}
                        sortable
                    ></Column>
                    {userData?.roleId === 1 && (
                        <Column
                            header="Action"
                            body={actionBodyTemplate}
                            className='d-flex justify-center'
                        ></Column>
                    )}
                </DataTable>

            </div>

            <Dialog
                className="p-3 background-white rounded-17"
                visible={showEditModal}
                modal
                header={headerElement}
                style={{ maxWidth: "800px", minWidth: "600px" }}
                onHide={() => {
                    if (!showEditModal) return;
                    setShowEditModal(false);
                }}
            >
                <Formik
                    enableReinitialize={true}
                    initialValues={editModal}
                    onSubmit={handleUpdateCoupon}
                >
                    {({ setFieldValue, handleReset, values }) => (
                        <Form className="px-2">
                            {/* <div className="mb-3">
                                <label htmlFor="name">Coupon Type</label>
                                <Field
                                    style={{ cursor: "not-allowed", backgroundColor: "#fff" }}
                                    className="form-control rounded-pill mt-1 color-dark"
                                    type="text"
                                    id="couponType"
                                    name="couponType"
                                    disabled
                                />
                                <ErrorMessage name="couponType" component={TextError} />
                            </div> */}
                            <div className="mb-3">
                                <label htmlFor="name">Coupon Code</label>
                                <Field
                                    className="form-control rounded-pill mt-1 color-dark"
                                    type="text"
                                    id="couponCode"
                                    name="couponCode"
                                />
                                <ErrorMessage name="couponCode" component={TextError} />
                            </div>

                            {/* <div className="mb-3">
                                <label htmlFor="name">Minimum Value</label>
                                <Field
                                    className="form-control rounded-pill mt-1 color-dark"
                                    type="text"
                                    id="minValue"
                                    name="minValue"
                                />
                                <ErrorMessage name="minValue" component={TextError} />
                            </div> */}
                            {/* <div className="mb-3" style={{ width: "100%" }}>
                                <label htmlFor="expiryDate">Expiration Date</label>
                                <div className="input-group">

                                    <DatePicker
                                        selected={values.expiryDate ? moment(values.expiryDate, 'YYYY-MM-DD').toDate() : null}
                                        onChange={(date) => {
                                            const formattedDate = moment(date).format('YYYY-MM-DD');
                                            setFieldValue("expiryDate", formattedDate);
                                        }}

                                        className="form-control rounded-pill mt-1 color-dark"
                                        placeholderText="Expiration Date"
                                        dateFormat={dateFormat === "MM-DD-YYYY" ? "MM/dd/yyyy" : "dd/MM/yyyy"}
                                        name="expiryDate"
                                        minDate={new Date()}
                                    />
                                </div>
                                <ErrorMessage name="expiryDate" component={TextError} />
                            </div> */}
                            {/* <div className="mb-3">
                                <label htmlFor="status">Status</label>
                                <Field
                                    as="select"
                                    className="form-control rounded-pill mt-1 color-dark"
                                    id="status"
                                    name="status"
                                >
                                    <option value="true">True</option>
                                    <option value="false">False</option>
                                </Field>
                                <ErrorMessage name="status" component={TextError} />
                            </div> */}
                            <div className="d-flex justify-content-between cstm-btn-group mt-3">
                                <Button
                                    type="button"
                                    className="btn btn-lg btn-secondary rounded-pill me-2"
                                    onClick={() => {
                                        handleReset();
                                        setShowEditModal(false);
                                    }}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    type="submit"
                                    className="btn btn-lg button-navy rounded-pill"
                                    disabled={loading}
                                >
                                    {loading ? (
                                        <span
                                            className="spinner-border spinner-border-sm me-2 text-dark"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    ) : (
                                        "Update"
                                    )}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Dialog>


            <Dialog
                className="p-3 background-white rounded-17"
                visible={visible}
                modal
                header={headerElement}
                style={{ maxWidth: "800px", minWidth: "600px" }}
                onHide={() => {
                    if (visible) setVisible(false);
                }}
            >
                <div className="px-2">
                    <CouponInput id="couponType" label="Coupon Type" value={selectedRow.couponType} />
                    <CouponInput id="couponCode" label="Coupon Code" value={selectedRow.couponCode} />
                    {/* <CouponInput id="discountType" label="Discount Type" value={selectedRow.discountType} />
                    <CouponInput id="discountValue" label="Discount Value" value={selectedRow.discountValue} /> */}
                    <CouponInput id="minValue" label="Minimum Value" value={selectedRow.minValue} />
                    <CouponInput id="couponAttempt" label="Coupon Attempt" value={selectedRow.couponAttempt} />
                    <CouponInput
                        id="status"
                        label="Status"
                        value={selectedRow.status ? 'Active' : 'Inactive'}
                    />
                    <CouponInput
                        id="expiryDate"
                        label="Expiration Date"
                        value={DateFormat(selectedRow.expiryDate)}
                    />
                </div>
            </Dialog>

            <ConfirmDialog className="toggleOffAlert" />

        </>

    )
}

export default CouponList
