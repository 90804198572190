import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getToken } from "../../Redux/Features/LocalStorage";
import { INDEX } from "../Constant";

const ProtectedRoutes = () => {
  const isAuth = getToken();

  return <>{isAuth ? <Outlet /> : <Navigate to={INDEX} />}</>;
};

export default ProtectedRoutes;
