import React, { useEffect, useState } from "react";
import Header from "../../Layouts/HeaderPage";
import SideBar from "../../Layouts/SideBar";
import UpdatesLog from "../../Components/Logs/UpdatesLog";
import TaskQueueStatus from "../../Components/Logs/TaskQueueStatus";
import TicketMasterLogs from "../../Components/Logs/TicketMasterLogs";
import KeywordReqLogs from "../../Components/Logs/KeywordReqLogs";
import AggreUpdates from "../../Components/Logs/AggreUpdates";
import KeywordRetFreq from "../../Components/Logs/KeywordRetFreq";

function Logs() {
  const [activeTab, setActiveTab] = useState('updates'); // Default active tab

  useEffect(() => {
    setActiveTab('updates')
  }, []);

  return (
    <>
      <div id="setting_page">
        <Header />
        <div id="wrapper">
          <SideBar />
          <section id="content-wrapper">
            <div class="welcome-user">
              <h2 className=" content-title mb-0 exDemiBold mb-2">Logs</h2>
            </div>
            <ul className="nav nav-tabs mb-3" id="settingTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link color-muted px-3 pb-2 border-0 exMedium fs-18 ${activeTab === 'updates' ? 'active' : ''}`}
                  id="updates-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#updates"
                  type="button"
                  role="tab"
                  aria-controls="updates"
                  aria-selected={activeTab === 'updates'}
                >
                  Updates Log
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link color-muted px-3 pb-2 border-0 exMedium fs-18 ${activeTab === 'taskqueue' ? 'active' : ''}`}

                  id="taskqueue-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#taskqueue"
                  type="button"
                  role="tab"
                  aria-controls="taskqueue"
                  aria-selected={activeTab === 'taskqueue'}
                >
                  Task Queue Status
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link color-muted px-3 pb-2 border-0 exMedium fs-18 ${activeTab === 'ticketmaster' ? 'active' : ''}`}

                  id="ticketmaster-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#ticketmaster"
                  type="button"
                  role="tab"
                  aria-controls="ticketmaster"
                  aria-selected={activeTab === 'ticketmaster'}
                >
                  Ticketmaster API Logs
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link color-muted px-3 pb-2 border-0 exMedium fs-18 ${activeTab === 'keywordrequests' ? 'active' : ''}`}

                  id="keywordrequests-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#keywordrequests"
                  type="button"
                  role="tab"
                  aria-controls="keywordrequests"
                  aria-selected={activeTab === 'keywordrequests'}
                >
                  Keyword Requests Log
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link color-muted px-3 pb-2 border-0 exMedium fs-18 ${activeTab === 'aggregated' ? 'active' : ''}`}

                  id="aggregated-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#aggregated"
                  type="button"
                  role="tab"
                  aria-controls="aggregated"
                  aria-selected={activeTab === 'aggregated'}
                >
                  Aggregated Updates
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link color-muted px-3 pb-2 border-0 exMedium fs-18 ${activeTab === 'keywordretrieval' ? 'active' : ''}`}

                  id="keywordretrieval-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#keywordretrieval"
                  type="button"
                  role="tab"
                  aria-controls="keywordretrieval"
                  aria-selected={activeTab === 'keywordretrieval'}
                >
                  Keyword Retrieval Frequency
                </button>
              </li>
            </ul>
            <div className="tab-content" id="logTabContent">
              {/* <!-- ----------------------Updates Log tab content----------------------------- --> */}

              <UpdatesLog
                activeTab={activeTab}
              />

              {/* <!-- ----------------------Task Queue Status tab content----------------------------- --> */}

              <TaskQueueStatus />

              {/* <!-- ----------------------Ticketmaster API Logs tab content----------------------------- --> */}

              <TicketMasterLogs />

              {/* <!-- ----------------------Keyword Requests Log tab content----------------------------- --> */}

              <KeywordReqLogs />

              {/* <!-- ----------------------Aggregated Updates tab content----------------------------- --> */}

              <AggreUpdates />

              {/* <!-- ----------------------Keyword Retrieval Frequency tab content----------------------------- --> */}

              <KeywordRetFreq />

            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Logs;
