import axios from "axios";
import {
    BASE_URL,
    CREATECOUPON,
    DELETECOUPON,
    GETCOUPON,
    SENDCOUPONTOUSER,
    UPDATECOUPON,
    GET_ALL_COUPON,
    COUPON_APPLIED
} from "../../../Config/Config";
import { getToken } from "../LocalStorage";
import { HeaderToken } from "../../../Utils/constant/commonFunction";

// Send Coupon to users API
const sendCouponToUserApi = async (couponData) => {
    const response = await axios.post(
        BASE_URL + SENDCOUPONTOUSER,
        couponData,
        HeaderToken(getToken())

    );
    return response.data;
};

// Create coupon API
const createCouponApi = async (userData) => {
    const response = await axios.post(
        BASE_URL + CREATECOUPON,
        userData,
        HeaderToken(getToken())
    );
    return response.data;
};

// Get all coupon API
const getCouponApi = async () => {
    const response = await axios.get(
        BASE_URL + GETCOUPON,
        HeaderToken(getToken())

    );
    return response.data;
};

// Update Coupon API
const updateCouponApi = async (data) => {
    const response = await axios.put(
        `${BASE_URL}${UPDATECOUPON}/${data.id}`,
        data,
        HeaderToken(getToken())
    );
    return response.data;
};

// Delete Coupon API
const deleteCouponApi = async (couponId) => {
    const response = await axios.delete(
        `${BASE_URL}${DELETECOUPON}/${couponId}`,
        HeaderToken(getToken())
    );
    return response.data;
};

// Get all coupon API By User
const getAllCoupon = async () => {
    const response = await axios.get(
        BASE_URL + GET_ALL_COUPON,
        HeaderToken(getToken())
    );
    return response.data;
};

// coupon Applied API
const couponApplied = async (userData) => {
    const response = await axios.post(
        BASE_URL + COUPON_APPLIED,
        userData,
        HeaderToken(getToken())
    );
    return response.data;
};


const couponService = {
    sendCouponToUserApi,
    createCouponApi,
    getCouponApi,
    updateCouponApi,
    deleteCouponApi,
    getAllCoupon,
    couponApplied
};

export default couponService;