import React from "react";

function Insight() {
  return (
    <div>
      <h1 className="text-lime exDemiBold text-center py-5 my-5">
        Coming Soon...
      </h1>
    </div>
  );
}

export default Insight;
