import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ResetPass } from "../../../Redux/Features/authReducer/authSlice";
import { ErrorMessage, Field, Form, Formik } from "formik";
import TextError from "../../../Components/TextError/index";
import { ForgetPasswordSchema } from "../../../Utils/Validation/Validation";
import { NavLink, useNavigate } from "react-router-dom";
import { SIGN_IN } from "../../../Routes/Constant";
import { ToastContainer } from "react-toastify";
import { getEmail } from "../../../Redux/Features/LocalStorage";
import { Button } from "primereact/button";
import Swal from "sweetalert2";

function ResetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); // State for loading indicator
  const {user}= useSelector((state) => state.Auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const InitialValues = {
    email: "",
    newPassword: "",
    conPassword: "",
    otp: "",
  };

  useEffect(() => {
    if (user?.success==true) {
      Swal.fire({
        title: 'Success',
        text: 'Reset password successfully',
        icon: 'success',
        timer: 2000,
        showConfirmButton: false,
        width: '300px',
        customClass: { popup: 'small-swal' }
      });
    }
  }, [user]);

  const email = getEmail();
  const onSubmit = (values, { resetForm }) => {
    setLoading(true); // Start loading indicator
    const data = {
      email: email,
      password: values.newPassword,
      otp: values.otp,
    };
    dispatch(ResetPass(data))
      .unwrap()
      .then((result) => {
        if (result?.success) {
          navigate(SIGN_IN);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false); // Stop loading indicator
        // resetForm();
      });
    // resetForm();
  };
  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <ToastContainer />
      <div class="container-fluid h-100">
        <section id="reset_page" class="h-100">
          <div class="row h-100 ">
            <div class="col-lg-6 px-0 h-100 d-none d-md-block px-0 h-100">
              <div class="left-box w-100 h-100 text-center position-relative">
                <div class="forgot-arrow">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/Assets/images/reset_lock.png"
                    }
                    class="img-fluid "
                    alt="..."
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6 px-0 h-100">
              <div class="right-box h-100 display-center text-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/Assets/images/client_images/horizontal_logo/Logo-white.png"
                  }
                  className="img-fluid w-25 text-center py-5 position-absolute top-0 d-md-none"
                  alt=""
                />
                <div class="inner-box m-auto h-auto ">
                  <h2 class="text-white mb-5 ">Reset your password</h2>
                  <Formik
                    initialValues={InitialValues}
                    validationSchema={ForgetPasswordSchema}
                    onSubmit={onSubmit}
                  >
                    <Form>
                      <div class="mb-1">
                        <div class="input-group">
                          <span
                            class="input-group-text bg-transparent border-end-0 input-border"
                            id="basic-addon1"
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/Assets/images/icons/lock.svg"
                              }
                              alt=""
                            />
                          </span>
                          <Field
                            placeholder="Enter OTP"
                            type="text"
                            className="form-control bg-transparent border-start-0 rounded-0 py-3 ps-0 input-border"
                            id="otp"
                            name="otp"
                            maxLength={6}
                          />
                        </div>
                        <ErrorMessage name="otp" component={TextError} />
                      </div>
                      <div class="mb-1 mt-3">
                        <div class="input-group">
                          <span
                            class="input-group-text bg-transparent border-end-0 input-border"
                            id="basic-addon1"
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/Assets/images/icons/lock.svg"
                              }
                              alt=""
                            />
                          </span>
                          <Field
                            placeholder="Enter New Password"
                            type="password"
                            className="form-control bg-transparent border-start-0 rounded-0 py-3 ps-0 input-border"
                            id="newPassword"
                            name="newPassword"
                            maxLength={16}
                          />
                        </div>
                        <ErrorMessage
                          name="newPassword"
                          component={TextError}
                        />
                      </div>
                      <div class="mb-1 mt-3">
                        <div class="input-group">
                          <span
                            class="input-group-text bg-transparent border-end-0 input-border"
                            id="basic-addon1"
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/Assets/images/icons/lock.svg"
                              }
                              alt=""
                            />
                          </span>
                          <Field
                            placeholder="Enter Confirm Password"
                            type={showPassword ? "text" : "password"}
                            className="form-control bg-transparent border-start-0 border-end-0 rounded-0 py-3 ps-0 input-border"
                            id="conPassword"
                            name="conPassword"
                            maxLength={16}
                          />
                          <span>
                            <Button
                              type="button"
                              className={` passEye pi border-start-0  ${
                                showPassword ? "pi-eye-slash" : "pi-eye"
                              }`}
                              onClick={handlePasswordVisibility}
                            />
                          </span>
                        </div>
                      </div>
                      <ErrorMessage name="conPassword" component={TextError} />
                      <button
                        type="submit"
                        class="btn button-light btn-lg form-control rounded-pill mt-4 mb-5"
                        disabled={loading} // Disable button while loading
                      >
                        {loading ? (
                          <span
                            className="spinner-border spinner-border-sm me-2 text-dark"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Reset Password"
                        )}
                      </button>
                    </Form>
                  </Formik>
                  <NavLink
                    to={SIGN_IN}
                    className="text-secondary text-decoration-none pb-5 mb-5"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/Assets/images/icons/left_arrow.svg"
                      }
                      alt=""
                      class="pe-2 "
                    />
                    Go back to login
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default ResetPassword;
