import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { useDispatch } from "react-redux";
import { DateFormat, errorToast } from "../../Utils/constant/commonFunction";
import { Dropdown } from "primereact/dropdown";
import { sendCouponToUserApi } from "../../Redux/Features/couponReducer/couponSlice";
import { ProgressSpinner } from "primereact/progressspinner";


const SendCoupon = (props) => {
    
    const { getAllCouponData } = props
    const dispatch = useDispatch();
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedCoupon, setSelectedCoupon] = useState(null);
    const [couponDropDownOptions, setCouponDropDownOptions] = useState([]);
    const [selectedCouponId, setSelectedCouponId] = useState(null);
    const [isSpinner, setIsSpinner] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    useEffect(() => {
        const data = props.getCouponApiData?.filter((item) => item.status === true).map((item) => (
            { id: item.id, code: item.couponCode, discountValue: item.discountValue }
        ));
        setCouponDropDownOptions(data);
    }, [props]);



    const cityItemTemplate = (option) => {
        return (
            <div className="flex justify-between p-2 border-b">
                <div className="flex-1">
                    <strong>Coupon Code:</strong> {option.couponCode}
                </div>
                <div className="flex-1">
                    <strong>Discounted value:</strong>
                    {option.percentOff ? `${option.percentOff}%` : `$ ${option.amountOff}`}
                </div>
            </div>
        );
    };

    const handleDropdownChange = (e) => {
        if (e?.value) {
            setSelectedCoupon(e.value);
            setSelectedCouponId(e?.value?.id);
        }
    };

    const sendCouponToUser = async (e) => {
        try {
            if (!selectedCoupon) {
                errorToast("Please select a coupon type.")
                setIsSpinner(false);
            } else {
                setIsSpinner(true)
                const couponIssueTo = selectedUsers.map(item => item.id);
                const objectData = {
                    couponId: selectedCouponId,
                    couponIssueTo
                }
                const result = await dispatch(sendCouponToUserApi(objectData)).unwrap()
                if (result.success === true) {
                    setSelectedUsers([]);
                    setSelectedCoupon(null);
                    setIsSpinner(false);
                }
            }

        } catch (error) {
            setIsSpinner(false);
        }
    };

    const renderHeader = () => {
        return (
            <div className="d-flex align-items-center justify-content-between flex-wrap">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText
                        value={globalFilterValue}
                        onChange={onGlobalFilterChange}
                        placeholder="Keyword Search"
                    />
                </IconField>

                {selectedUsers?.length > 0 && (
                    <div className="action-buttons_n d-flex align-items-center justify-content-between me-3 ms-md-3 mb-sm-2">
                        <div className=" d-flex align-items-center fs-18 " style={{ paddingRight: "25px" }}>
                            <div
                                className="card flex justify-content-center"
                                style={{ height: "40px", paddingLeft: "10px" }}
                            >
                                <Dropdown
                                    value={selectedCoupon}
                                    onChange={handleDropdownChange}
                                    options={getAllCouponData?.data}
                                    optionLabel="couponCode"
                                    itemTemplate={cityItemTemplate}
                                    placeholder="Select a Coupon"
                                    className={`w-full md:w-12rem removecontent`}
                                    checkmark={true}
                                    highlightOnSelect={true}
                                    style={{ width: "200px" }}

                                />
                            </div>

                            <div className="vr ms-md-4 ms-2 me-md-5 me-2"></div>
                            <div className="mb-0 ">Send Coupon</div>{" "}
                            <Button
                                icon="pi pi-send"
                                className="ms-3 rounded-circle text-navy mint-30 fw-bold"
                                rounded
                                outlined
                                severity="secondary"
                                onClick={(e) => sendCouponToUser(e)}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    };
    const header = renderHeader();

    const onSelectedUsers = (e) => {
        setSelectedUsers(e.value);
    };

    const statusBodyTemplate = (varient) => {
        return (
            <Tag
                value={
                    varient.status === "active"
                        ? "Active"
                        : varient.status === "inactive"
                            ? "Inactive"
                            : "Unknown"
                }
                className={
                    varient.status === "active"
                        ? "mint-30 color-dark fw-light rounded-pill fs-14 px-3 py-2 exMono"
                        : varient.status === "inactive"
                            ? "red-10 color-dark fw-light rounded-pill fs-14 px-3 py-2 exMono"
                            : "lime-30 color-dark fw-light rounded-pill fs-14 px-3 py-2 exMono"
                }
            ></Tag>
        );
    };

    return (
        <>
            <div
                className={`tab-pane fade shadow-sm mt-4 rounded-17 sendCoupon-table ${props.activeTab === 'sendCoupon' ? 'show active' : ''}`}
                id="sendCoupon"
                role="tabpanel"
                aria-labelledby="sendCoupon-tab"
            >
                <h2>Send Coupon</h2>
                {!isSpinner ? (
                    <DataTable
                        className="editNick sendCouponTable"
                        value={props.getUsersAPIData || []}
                        selectionMode={"checkbox"}
                        selection={selectedUsers}
                        onSelectionChange={(e) => onSelectedUsers(e)}
                        dataKey="id"
                        tableStyle={{ minWidth: "50rem" }}
                        responsiveLayout="scroll"
                        size="small"
                        showGridlines
                        removableSort
                        paginator
                        rows={6}
                        rowsPerPageOptions={[6, 20, 30, 50]}
                        paginatorTemplate="RowsPerPageDropdown  PrevPageLink CurrentPageReport NextPageLink "
                        currentPageReportTemplate="{first} to {last} of {totalRecords}"
                        filters={filters}
                        header={header}
                    >
                        <Column
                            selectionMode="multiple"
                            headerStyle={{ width: "3rem", height: "4rem" }}
                            field="id"
                            style={{ marginRight: "2rem" }}
                        ></Column>
                        <Column field="fname" header="First Name" sortable></Column>
                        <Column field="lname" header="Last Name" sortable></Column>
                        <Column field="email" header="Email Address" sortable></Column>
                        <Column field="phoneNumber" header="Phone Number" sortable></Column>
                        <Column field="allListingCount" header="Total Listings" sortable></Column>
                        <Column field="automatedListingCount" header="Automated Listings" sortable></Column>

                        <Column
                            field="status"
                            header="Status"
                            className="exMono"
                            body={statusBodyTemplate}
                            sortable
                        ></Column>
                        <Column
                            field="createdAt"
                            header="Created Date"
                            sortable
                            body={(row) => <span>{DateFormat(row.createdAt)}</span>}
                        ></Column>
                    </DataTable>
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                        <div>
                            <ProgressSpinner
                                style={{ height: "50px" }}
                                strokeWidth="3"
                                animationDuration="0.8s"
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default SendCoupon;
