import axios from "axios";
import { getToken } from "../LocalStorage";
import { HeaderToken } from "../../../Utils/constant/commonFunction";
import {
  BASE_URL,
  PURCHASE_SUBSCRIPTIONS
} from "../../../Config/Config"

// Fetch purchased subscriptions
const getPurchasedSubscriptions = async () => {
  const response = await axios.get(
    BASE_URL + PURCHASE_SUBSCRIPTIONS,
    HeaderToken(getToken())
  );
  return response?.data;
};

const settingService = {
  getPurchasedSubscriptions
}
export default settingService;