export const INDEX = "/";
export const PAGE_NOT_FOUND = "*";
export const BACK_TO_PAGE = -1;

//########### Publics Routes  #######################
export const SIGN_IN = "/login";
export const SIGN_UP = "/signup";
export const FORGET_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password";
export const CALLBACK="/callback";


//###########  Protected Routes  #####################
export const DASHBOARD = "/admin/dashboard";
export const HOSTAWAYCONNECTION = "/admin/hostaway";
export const GUESTYCONNECTION = "/admin/guesty";
export const CONNECTEDHOSTAWAYUSERLIST="/admin/connecteduserlist";
export const CONNECTION = "/admin/connection";
export const LISTING = "/admin/listing";
export const USERLIST = "/admin/userlist";
export const SETTING = "/admin/profile";
export const LOGS = "/logs"
export const PAYMENT = "/payment"
export const PAYMENT_SUCCESS = "/payment-success"
export const PAYMENT_REJECTED = "/payment-rejected"
export const SETTINGS = "/settings"
export const INSIGHTS = "/insights"
export const NOTIFICATION = "/notification"
export const UPDATED_HISTORY = "/updated_history/:recordId"
export const PROPERTY_DETAILS = "/propertydetails"
export const COUPON = "/coupons";

export const FAQ = "https://knowledgebase.autorank.com/knowledge-base/faqs-1";
export const PRODUCT_ROADMAP = "https://knowledgebase.autorank.com/knowledge-base/product-roadmap";
export const FEATURE_REQUEST = "https://knowledgebase.autorank.com/knowledge-base/feature-requests";



    