import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  getPaymentId,
  removePaymentId,
} from "../../Redux/Features/LocalStorage";
import { useDispatch } from "react-redux";
import { checkPaymentAboutPaymentId } from "../../Redux/Features/authReducer/authSlice";
import SideBar from "../../Layouts/SideBar";
import Header from "../../Layouts/HeaderPage";
import { DASHBOARD} from "../../Routes/Constant";

const PaymentSuccessfully = () => {

  const navigate=useNavigate();
  const dispatch = useDispatch();
  // const localPaymentId = getPaymentId();

  // useEffect(() => {
  //   if (localPaymentId) {
  //     dispatch(checkPaymentAboutPaymentId({ paymentId: localPaymentId }))
  //       .unwrap()
  //       .then((result) => {
  //         removePaymentId();
  //       });
  //   }
  // }, []);

  const backpage=()=>{
    navigate(DASHBOARD);
  }

  return (
    <>
      <div id="payment_success">
        <Header />
        <div id="wrapper h-100">
          <SideBar />
          <section id="content-wrapper" className="vh-100">
            <div class="bg_404 ">
              <div class="msg_404  text-center mb-4 ">
                <div className="success_bg color-purple  m-auto mb-5 position-relative">
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="position-abolute top-50 start-50 "
                  >
                    <g clip-path="url(#clip0_3249_16742)">
                      <path
                        d="M37 0H11C4.934 0 0 4.934 0 11V13C0 14.902 0.764 16.63 2 17.894V37C2 43.066 6.934 48 13 48H17C18.658 48 20 46.658 20 45C20 43.342 18.658 42 17 42H13C10.244 42 8 39.756 8 37V20H41C44.86 20 48 16.86 48 13V11C48 4.934 43.066 0 37 0ZM42 13C42 13.552 41.552 14 41 14H7C6.448 14 6 13.552 6 13V11C6 8.244 8.244 6 11 6H37C39.756 6 42 8.244 42 11V13ZM36 24C29.372 24 24 29.372 24 36C24 42.628 29.372 48 36 48C42.628 48 48 42.628 48 36C48 29.372 42.628 24 36 24ZM43.366 35.424L37.96 40.652C37.056 41.544 35.856 41.994 34.654 41.994C33.452 41.994 32.248 41.544 31.328 40.646L28.62 37.982C27.83 37.208 27.82 35.942 28.592 35.154C29.364 34.364 30.63 34.352 31.42 35.126L34.128 37.788C34.416 38.072 34.888 38.066 35.172 37.784L40.598 32.536C41.392 31.774 42.66 31.796 43.426 32.594C44.19 33.39 44.164 34.656 43.368 35.422L43.366 35.424Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3249_16742">
                        <rect width="48" height="48" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <p class="exDemiBold display-5 text-purple mb-4">Thank You!</p>
                <h4 class="exMedium mb-4">Payment done Successfully.</h4>
                <p className="lh-lg w-75 m-auto">
                Click here to return to Dashboard.
                </p>
                <button
                  class="btn btn-lg button-navy rounded-pill mt-5"
                  label="Link"
                  link
                  onClick={()=>backpage()}
                >
                  <svg
                    className="me-2"
                    width="28"
                    height="28"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_319_15512"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="28"
                      height="28"
                    >
                      <rect
                        x="20"
                        y="20.5"
                        width="20"
                        height="20"
                        transform="rotate(-180 20 20.5)"
                        fill="white"
                      />
                    </mask>
                    <g mask="url(#mask0_319_15512)">
                      <path
                        d="M8.34211 6.75L9.24211 7.91667L7.91579 9.66667H17.5V11.3333L7.91579 11.3333L9.22632 13.0833L8.32632 14.25L5.5 10.5L8.34211 6.75Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                  Back to Dashboard
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
export default PaymentSuccessfully;
