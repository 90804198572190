import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "./authService";
import {
  successToast,
  errorToast,
} from "../../../Utils/constant/commonFunction";

const user = localStorage.getItem("user") || {};
const initialState = {
  user: user ? user : null,
  isLoading: false,
  isError: false,
  isSuccess: false,
  response: null,
  registerAuthData: "",
  userRegistrationData: "",
  loginUserData: {},
  subscriptionResponse: null,
  token: "",
  message: "",
  GetUserDetailsData: "",
  ChangePasswordData: "",
  ForgetPassData: "",
  GetUserListData: "",
  HostawayAuthData: "",
  GenrateAccessTokenForGuestyData: "",
  HostawayLIstingsData: "",
  UpdateProfileData: "",
  CheckHoatawayConnectionData: "",
  manualUpdateListingData: "",
  autoUpdateListingData: "",
  includeExcludeUpdateData: "",
  inActiveUserData: "",
  checkPaymentAboutPaymentIdData: "",
  inactiveHostawayListingData: "",
  addPaymentCardData: "",
  getBillingDetailsData: "",
  addAndupdateBillingDetailsData: "",
  resetSingleAndMultiplePropertyData: "",
  googleLoginData: "",
  googleLoginCallbackData: "",
  makeAdminImpersonateUserData: "",
  adminIncressesLimitOfListingData: "",
  updateGuestyTokenByAdminData:""

};

/* Authenticate user before registration*/
export const registerAuth = createAsyncThunk(
  "registrationAuth",
  async (data, thunkAPI) => {
    try {
      return await authService.registerAuth(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      errorToast(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/* New User Registration  */
export const UserRegistration = createAsyncThunk(
  "userRegisteration",
  async (userDetails, thunkAPI) => {
    try {
      return await authService.userRegistrationApi(userDetails);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString(); // we have to get error message
      errorToast(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/* User login api with asyncThunk */
export const loginUser = createAsyncThunk(
  "checkLogin",
  async (user, thunkAPI) => {
    try {
      return await authService.LoginAPI(user);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      errorToast(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/* logout User Acount  */
export const logout = createAsyncThunk("/auth/logout", async (userdata) => {
  return await authService.LogoutAPI(userdata);
});

/* Forgot Password API */
export const ForgetPass = createAsyncThunk(
  "auth/forgetpassword",
  async (user, thunkAPI) => {
    try {
      return await authService.ForgetpasswordAPI(user);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      errorToast(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/* Reset Password */
export const ResetPass = createAsyncThunk(
  "auth/resetpassword",
  async (user, thunkAPI) => {
    try {
      return await authService.ResetpasswordAPI(user);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      errorToast(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/*  Host Away Auth */
export const HostawayAuth = createAsyncThunk(
  "hostawayConnection",
  async (data, thunkAPI) => {
    try {
      return await authService.HostawayAuthAPI(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      errorToast(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/*  Genrate AccessTokenForGuesty Auth */
export const GenrateAccessTokenForGuesty = createAsyncThunk(
  "AccessTokenForGuesty",
  async (data, thunkAPI) => {
    try {
      return await authService.GenrateAccessTokenForGuestyAPI(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      errorToast(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/*  Host Away Listing */
export const HostawayLIstings = createAsyncThunk(
  "getHostwayListingFromDatabase",
  async (data, thunkAPI) => {
    try {
      const propertyData =  await authService.HostawayListingsAPI(data);
       return propertyData;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      errorToast(error.response.data, "connection");
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/* Get All Profile list  */
export const AllProfileListApi = createAsyncThunk(
  "sticky/allprofile",
  async (data, thunkAPI) => {
    try {
      return await authService.allProfileAPI(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/* Add Profile for sub user   */
export const AddProfileApi = createAsyncThunk(
  "sticky/addprofileApi",
  async (data, thunkAPI) => {
    try {
      return await authService.CreateProfileAPI(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/* Update Profile*/
export const UpdateProfileApi = createAsyncThunk(
  "updateUserProfile",
  async (data, thunkAPI) => {
    try {
      return await authService.EditProfileAPI(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      errorToast(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/* delete Profile account */
export const DeleteProfileApi = createAsyncThunk(
  "sticky/deleteProfileApi",
  async (data, thunkAPI) => {
    try {
      return await authService.deleteProfileAPI(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/* Change Password  */
export const ChangePasswordApi = createAsyncThunk(
  "changePassword",
  async (data, thunkAPI) => {
    try {
      return await authService.ChangePasswordAPI(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      errorToast(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/* Get Profile Details   */
export const GetUserDetailsApi = createAsyncThunk(
  "getAllUserList",
  async (data, thunkAPI) => {
    try {
      return await authService.GetUserDetailsApi();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/* Get Profile List   */
export const GetUserListApi = createAsyncThunk(
  "sticky/GetUserListApi",
  async (data, thunkAPI) => {
    try {
      return await authService.GetUserListApi();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/* Check Hostaway Connection   */
export const CheckHoatawayConnection = createAsyncThunk(
  "checkValidConnection",
  async (_, thunkAPI) => {
    try {
      return await authService.CheckHoatawayConnectionApi();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/* Manually update listing/record  */
export const manualUpdateListing = createAsyncThunk(
  "manualUpdataData",
  async (data, thunkAPI) => {
    try {
      return await authService.manualUpdateListing(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      errorToast(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/* Automatically update listing/record  */
export const autoUpdateListing = createAsyncThunk(
  "updateListingDataStatus",
  async (data, thunkAPI) => {
    try {
      return await authService.autoUpdateListing(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      errorToast(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/* Include exclude discription update listing */
export const includeExcludeUpdate = createAsyncThunk(
  "updateIncludeExcludeStatus",
  async (data, thunkAPI) => {
    try {
      return await authService.includeExcludeUpdateListing(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      errorToast(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/* InActivate user by Admin */
export const inActivateUser = createAsyncThunk(
  "inactiveUserByAdmin",
  async (data, thunkAPI) => {
    try {
      return await authService.inActivateUser(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      errorToast(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/* Check Payment About Payment Id  */
export const checkPaymentAboutPaymentId = createAsyncThunk(
  "checkPaymentAboutPaymentId",
  async (data, thunkAPI) => {
    try {
      return await authService.checkPaymentAboutPaymentId(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      errorToast(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/* Inactive Hostaway Listing */
export const inactiveHostawayListing = createAsyncThunk(
  "inactiveHostawayListing",
  async (data, thunkAPI) => {
    try {
      return await authService.inactiveHostawayListing(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      errorToast(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/* Add Payment Card */
export const addPaymentCard = createAsyncThunk(
  "addPaymentCard",
  async (data, thunkAPI) => {
    try {
      return await authService.addPaymentCard(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      errorToast(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/* Get Billig Details   */
export const getBillingDetails = createAsyncThunk(
  "getBillingDetails",
  async (_, thunkAPI) => {
    try {
      return await authService.getBillingDetails();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/* Add and update Billing Details  */
export const addAndupdateBillingDetails = createAsyncThunk(
  "addAndupdateBillingDetails",
  async (data, thunkAPI) => {
    try {
      return await authService.addAndupdateBillingDetails(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      errorToast(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/*Reset Single And Multiple Property Data  */
export const resetSingleAndMultipleProperty = createAsyncThunk(
  "resetSingleAndMultipleProperty",
  async (data, thunkAPI) => {
    try {
      return await authService.resetSingleAndMultipleProperty(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      errorToast(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/* Google Login API   */
export const googleLogin = createAsyncThunk(
  "googleLogin",
  async (_, thunkAPI) => {
    try {
      return await authService.googleLogin();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/* Google Login Callback API   */
export const googleLoginCallback = createAsyncThunk(
  "googleLoginCallback",
  async (_, thunkAPI) => {
    try {
      return await authService.googleLoginCallback();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/*make Admin Impersonate User  */
export const makeAdminImpersonateUser = createAsyncThunk(
  "makeAdminImpersonateUser",
  async (data, thunkAPI) => {
    try {
      return await authService.makeAdminImpersonateUser(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      errorToast(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/*make Admin Increse Limit  */
export const adminIncressesLimitOfListing = createAsyncThunk(
  "adminIncressesLimitOfListing",
  async (data, thunkAPI) => {
    try {
      return await authService.adminIncressesLimitOfListing(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      errorToast(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/*Reset Guesty Token by Admin  */
export const updateGuestyTokenByAdmin = createAsyncThunk(
  "updateGuestyTokenByAdmin",
  async (data, thunkAPI) => {
    try {
      return await authService.updateGuestyTokenByAdmin(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      errorToast(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/*  For Slice */
const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset: (state) => {
      state.isSuccess = false;
      state.isError = false;
      state.isLoading = false;
      state.message = "";
      state.response = null;
      state.subscriptionResponse = null;
    },
  },

  extraReducers: (builder) => {
    builder
      // Authenticate user befor registration
      .addCase(registerAuth.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerAuth.fulfilled, (state, { payload }) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.response = payload.success;
        state.message = payload.message;
        successToast(payload.message);
        state.registerAuthData = payload;
      })
      .addCase(registerAuth.rejected, (state, { payload }) => {
        state.isError = true;
        state.isLoading = false;
        state.user = null;
        state.isSuccess = false;
        state.response = false;
        state.message = payload.message;
      })

      // Register new user
      .addCase(UserRegistration.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(UserRegistration.fulfilled, (state, { payload }) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.response = payload.success;
        state.message = payload.message;
        successToast(payload.message);
        state.userRegistrationData = payload;
      })
      .addCase(UserRegistration.rejected, (state, { payload }) => {
        state.isError = true;
        state.isLoading = false;
        state.user = null;
        state.isSuccess = false;
        state.response = false;
        state.message = payload.message;
      })

      /* login User */
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.loginUserData = payload;
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        state.isError = true;
        state.isLoading = false;
        state.loginUserData = null;
        state.message = payload;
      })

      /* Forget password User */
      .addCase(ForgetPass.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ForgetPass.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
        state.ForgetPassData = payload;
      })
      .addCase(ForgetPass.rejected, (state, { payload }) => {
        state.isError = true;
        state.isLoading = false;
        state.ForgetPassData = payload;
        state.message = payload;
      })
      /* reset password */
      .addCase(ResetPass.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ResetPass.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
        state.user = payload;
      })
      .addCase(ResetPass.rejected, (state, { payload }) => {
        state.isError = true;
        state.isLoading = false;
        state.user = null;
        state.message = payload;
      })

      /* GetUserDetailsApi */
      .addCase(GetUserDetailsApi.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetUserDetailsApi.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
        state.GetUserDetailsData = payload;
      })
      .addCase(GetUserDetailsApi.rejected, (state, { payload }) => {
        state.isError = true;
        state.isLoading = false;
        state.GetUserDetailsData = null;
        state.message = payload;
      })
      /* GetUserListApi */
      .addCase(GetUserListApi.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetUserListApi.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
        state.GetUserListData = payload;
      })
      .addCase(GetUserListApi.rejected, (state, { payload }) => {
        state.isError = true;
        state.isLoading = false;
        state.GetUserListData = null;
        state.message = payload;
      })

      /* ChangePasswordApi */
      .addCase(ChangePasswordApi.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ChangePasswordApi.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
        state.ChangePasswordData = payload;
        successToast(payload.message);
      })
      .addCase(ChangePasswordApi.rejected, (state, { payload }) => {
        state.isError = true;
        state.isLoading = false;
        state.ChangePasswordData = "";
        state.message = payload;
      })
      /* Update profile */
      .addCase(UpdateProfileApi.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(UpdateProfileApi.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
        state.UpdateProfileData = payload;
        successToast(payload.message);
      })
      .addCase(UpdateProfileApi.rejected, (state, { payload }) => {
        state.isError = true;
        state.isLoading = false;
        state.UpdateProfileData = null;
        state.message = payload;
      })

      /* Host away Auth */
      .addCase(HostawayAuth.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(HostawayAuth.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
        state.HostawayAuthData = payload;
        successToast(payload.message);
      })
      .addCase(HostawayAuth.rejected, (state, { payload }) => {
        state.isError = true;
        state.isLoading = false;
        state.HostawayAuthData = null;
        state.message = payload;
      })

       /* Genrate AccessTokenForGuesty Auth */
      .addCase(GenrateAccessTokenForGuesty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GenrateAccessTokenForGuesty.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
        state.GenrateAccessTokenForGuestyData = payload;
        successToast(payload.message);
      })
      .addCase(GenrateAccessTokenForGuesty.rejected, (state, { payload }) => {
        state.isError = true;
        state.isLoading = false;
        state.GenrateAccessTokenForGuestyData = null;
        state.message = payload;
      })

      /* Host away Listing */
      .addCase(HostawayLIstings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(HostawayLIstings.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
        state.HostawayLIstingsData = payload;
        // successToast(payload.message)
      })
      .addCase(HostawayLIstings.rejected, (state, { payload }) => {
        state.isError = true;
        state.isLoading = false;
        state.HostawayLIstingsData = null;
        state.message = payload;
      })

      /* Host away CheckHoatawayConnection */
      .addCase(CheckHoatawayConnection.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(CheckHoatawayConnection.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
        state.CheckHoatawayConnectionData = payload;
      })
      .addCase(CheckHoatawayConnection.rejected, (state, { payload }) => {
        state.isError = true;
        state.isLoading = false;
        state.HostawayLIstingsData = null;
        state.message = payload;
      })

      /* Manual update listing */
      .addCase(manualUpdateListing.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(manualUpdateListing.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
        state.manualUpdateListingData = payload;
        successToast(payload.message);
      })
      .addCase(manualUpdateListing.rejected, (state, { payload }) => {
        state.isError = true;
        state.isLoading = false;
        state.manualUpdateListingData = null;
        state.message = payload;
      })

      /* Auto update listing */
      .addCase(autoUpdateListing.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(autoUpdateListing.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
        state.autoUpdateListingData = payload;
        successToast(payload.message);
      })
      .addCase(autoUpdateListing.rejected, (state, { payload }) => {
        state.isError = true;
        state.isLoading = false;
        state.autoUpdateListingData = null;
        state.message = payload;
      })
      
      /* Include/Exclude discription update listing */
      .addCase(includeExcludeUpdate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(includeExcludeUpdate.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
        state.includeExcludeUpdateData = payload;
        successToast(payload.message);
      })
      .addCase(includeExcludeUpdate.rejected, (state, { payload }) => {
        state.isError = true;
        state.isLoading = false;
        state.includeExcludeUpdateData = null;
        state.message = payload;
      })

      /* InActivate User By Admin*/
      .addCase(inActivateUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(inActivateUser.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
        state.inActiveUserData = payload;
        successToast(payload.message);
      })
      .addCase(inActivateUser.rejected, (state, { payload }) => {
        state.isError = true;
        state.isLoading = false;
        state.inActiveUserData = null;
        state.message = payload;
      })

      /*Check Payment About Payment Id*/
      .addCase(checkPaymentAboutPaymentId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(checkPaymentAboutPaymentId.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
        state.checkPaymentAboutPaymentIdData = payload;
        successToast(payload.message);
      })
      .addCase(checkPaymentAboutPaymentId.rejected, (state, { payload }) => {
        state.isError = true;
        state.isLoading = false;
        state.checkPaymentAboutPaymentIdData = null;
        state.message = payload;
      })

      /* inactive Hostaway Listing */
      .addCase(inactiveHostawayListing.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(inactiveHostawayListing.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
        state.inactiveHostawayListingData = payload;
        successToast(payload.message);
      })
      .addCase(inactiveHostawayListing.rejected, (state, { payload }) => {
        state.isError = true;
        state.isLoading = false;
        state.inactiveHostawayListingData = null;
        state.message = payload;
      })

      /* Add Payment Card */
      .addCase(addPaymentCard.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addPaymentCard.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
        state.addPaymentCardData = payload;
        successToast(payload.message);
      })
      .addCase(addPaymentCard.rejected, (state, { payload }) => {
        state.isError = true;
        state.isLoading = false;
        state.addPaymentCardData = null;
        state.message = payload;
      })

      /* getBillingDetails */
      .addCase(getBillingDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBillingDetails.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
        state.getBillingDetailsData = payload;
      })
      .addCase(getBillingDetails.rejected, (state, { payload }) => {
        state.isError = true;
        state.isLoading = false;
        state.getBillingDetailsData = null;
        state.message = payload;
      })
      
      /* Add and update Billing Details */
      .addCase(addAndupdateBillingDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addAndupdateBillingDetails.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
        state.addAndupdateBillingDetailsData = payload;
        successToast(payload.message);
      })
      .addCase(addAndupdateBillingDetails.rejected, (state, { payload }) => {
        state.isError = true;
        state.isLoading = false;
        state.addAndupdateBillingDetailsData = null;
        state.message = payload;
      })

      /* Reset Single And Multiple Property Data */
      .addCase(resetSingleAndMultipleProperty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetSingleAndMultipleProperty.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
        state.resetSingleAndMultiplePropertyData = payload;
        successToast(payload.message);
      })
      .addCase(resetSingleAndMultipleProperty.rejected, (state, { payload }) => {
        state.isError = true;
        state.isLoading = false;
        state.addAndupdateBillingDetailsData = null;
        state.message = payload;
      })

      /* Google Login */
      .addCase(googleLogin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(googleLogin.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
        state.googleLoginData = payload;
      })
      .addCase(googleLogin.rejected, (state, { payload }) => {
        state.isError = true;
        state.isLoading = false;
        state.googleLoginData = null;
        state.message = payload;
      })

      /* Google Login Callback */
      .addCase(googleLoginCallback.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(googleLoginCallback.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
        state.googleLoginCallbackData = payload;
      })
      .addCase(googleLoginCallback.rejected, (state, { payload }) => {
        state.isError = true;
        state.isLoading = false;
        state.googleLoginCallbackData = null;
        state.message = payload;
      })

      /* make Admin Impersonate User */
      .addCase(makeAdminImpersonateUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(makeAdminImpersonateUser.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
        state.makeAdminImpersonateUserData = payload;
        successToast(payload.message);
      })
      .addCase(makeAdminImpersonateUser.rejected, (state, { payload }) => {
        state.isError = true;
        state.isLoading = false;
        state.makeAdminImpersonateUserData = null;
        state.message = payload;
      })

      /* make Admin Increase limit */
      .addCase(adminIncressesLimitOfListing.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(adminIncressesLimitOfListing.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
        state.adminIncressesLimitOfListingData = payload;
        successToast(payload.message);
      })
      .addCase(adminIncressesLimitOfListing.rejected, (state, { payload }) => {
        state.isError = true;
        state.isLoading = false;
        state.adminIncressesLimitOfListingData = null;
        state.message = payload;
      })

        /* Reset Guesty Token by Admin */
        .addCase(updateGuestyTokenByAdmin.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(updateGuestyTokenByAdmin.fulfilled, (state, { payload }) => {
          state.isSuccess = true;
          state.isError = false;
          state.isLoading = false;
          state.updateGuestyTokenByAdminData = payload;
          successToast(payload.message);
        })
        .addCase(updateGuestyTokenByAdmin.rejected, (state, { payload }) => {
          state.isError = true;
          state.isLoading = false;
          state.addAndupdateBillingDetailsData = null;
          state.message = payload;
        })

  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
