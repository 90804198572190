import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from 'primereact/column';
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { FilterMatchMode } from "primereact/api";
import { getUpdateLogs } from "../../../Redux/Features/logsReducer/logSlice";

function TicketMasterLogs() {

  const dispatch = useDispatch();

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [defaultRows, setDefaultRows] = useState(8);
  const [defaultPage, setDefaultPage] = useState(0);

  console.log("defaultRows::", defaultRows);
  console.log("defaultPage::", defaultPage);
  // const { getUpdateLogsData } = useSelector((state) => state.Logs);

  useEffect(() => {
    dispatch(getUpdateLogs({
      "dateRange": "monthly",
      "page": 1,
      // "limit": 100,
      "limit": defaultRows,
      "search": "",
      "sortField": "createdAt",
      "sortOrder": "DESC"
    }))
  }, [defaultRows]);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <h4 className=' content-title mb-0 exDemiBold mb-3 p-3'>Ticketmaster API Logs</h4>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <Button
            icon="pi pi-sliders-h"
            className="filter-btn rounded-pill px-3 py-2 "
            label="Filter"
            type="button"
            // id="filterDrop"
            // data-bs-toggle="dropdown"
            // aria-expanded="false"
            outlined
            severity="secondary"
          />

          <div className="dropdown">
            <IconField iconPosition="left">
              <InputIcon className="pi pi-search" />
              <InputText
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
                placeholder="Keyword Search"
              />
            </IconField>
          </div>
        </div>

      </div>
    );
  };

  const handlePageChange = (event) => {

    // console.log("event::",event);
    // event.first: Index of the first record
    // event.rows: Number of rows to display
    // console.log("Rows per page changed to:", event.rows);
    // console.log("First row index:", event.first);

    // Perform actions, like updating state or making an API call
    setDefaultRows(event.rows); // Update state if needed
    setDefaultPage(event.page);
  };

  return (
    <div
      className="tab-pane fade shadow-sm mt-4 rounded-17"
      id="ticketmaster"
      role="tabpanel"
      aria-labelledby="ticketmaster-tab"
    >
       <div class="row">
        <div class="col-2 commoncard" style={{ background: '#f4f2ff', color: '#8d7cf5' }}>
          <div class="card-body ps-2 p-0 mb-2">
            <h4 class="card-title fs-1 fw-bold exBold text-center">0</h4>
            <p class="card-text mb-2 exMedium fs-6 text-center">Total API Requests</p>
          </div>
        </div>

        <div class="col-2 commoncard" style={{ background: '#f0fff9', color: '#0fb46e' }}>
          <div class="card-body ps-2 p-0 mb-2">
            <h4 class="card-title fs-1 fw-bold exBold text-center">0</h4>
            <p class="card-text mb-2 exMedium fs-6 text-center">Successful Requests</p>
          </div>
        </div>

        <div class="col-2 commoncard" style={{ background: '#fff2f2', color: '#d34242' }}>
          <div class="card-body ps-2 p-0 mb-2">
            <h4 class="card-title fs-1 fw-bold exBold text-center">0</h4>
            <p class="card-text mb-2 exMedium fs-6 text-center">Faild Requests</p>
          </div>
        </div>

        <div class="col-2 commoncard" style={{ background: '#f5f5f5', color: '#4d4d50' }}>
          <div class="card-body ps-2 p-0 mb-2">
            <h4 class="card-title fs-1 fw-bold exBold text-center">Concert</h4>
            <p class="card-text mb-2 exMedium fs-6 text-center">Most Frequent Event</p>
          </div>
        </div>

        <div class="col-2 commoncard" style={{ background: '#fcffe8', color: '#9aaa35' }}>
          <div class="card-body ps-2 p-0 mb-2">
            <h4 class="card-title fs-1 fw-bold exBold text-center">1.8 sec</h4>
            <p class="card-text mb-2 exMedium fs-6 text-center">Avg. Response Time</p>
          </div>
        </div>
      </div>

      <DataTable
        className="shadow-sm rounded-17"
        // value={getUpdateLogsData?.data || []}
        value={[]}
        header={renderHeader}
        dataKey="id"
        tableStyle={{ minWidth: "50rem" }}
        responsiveLayout="scroll"
        size="small"
        showGridlines
        stripedRows
        removableSort
        paginator
        rows={defaultRows}
        rowsPerPageOptions={[8, 20, 30, 50]}
        paginatorTemplate="RowsPerPageDropdown  PrevPageLink CurrentPageReport NextPageLink "
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        onPage={(e) => handlePageChange(e)}
        filters={filters}
        emptyMessage="No Ticketmaster API Logs Found"
      >
        <Column field="id" header="Request ID" sortable></Column>
        <Column field="recordId" header="Listing/Property" sortable></Column>
        <Column field="updateType" header="Event Requested" sortable></Column>
        <Column field="status" header="Request Details" sortable></Column>
        <Column field="retries" header="Response" ></Column>
        <Column field="message" header="Status" sortable></Column>
        <Column field="message" header="Timestamp" sortable></Column>

      </DataTable>
    </div>
  );
}

export default TicketMasterLogs;
