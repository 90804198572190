import React from "react";

function KeywordReqLogs() {

    return (
        <div
            className="tab-pane fade shadow-sm mt-4 rounded-17"
            id="keywordrequests"
            role="tabpanel"
            aria-labelledby="keywordrequests-tab"
        >
            <h2>Coming Soon</h2>
        </div>
    );
}

export default KeywordReqLogs;
