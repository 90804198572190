import axios from "axios";
import { getToken, getToken2 } from "../LocalStorage";
import { HeaderToken } from "../../../Utils/constant/commonFunction";
import { 
    BASE_URL,
    GET_UPDATE_LOGS
} from "../../../Config/Config"

// Fetch getUpdateLogs  API
  const getUpdateLogs = async (data) => {
    const response = await axios.post(
      BASE_URL + GET_UPDATE_LOGS,
      data,
      HeaderToken(getToken2() ? getToken2() : getToken())
    );
    return response?.data;
  };

  const logService = {
    getUpdateLogs
  }

  export default logService;