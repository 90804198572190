import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetUserDetailsApi } from "../../../Redux/Features/authReducer/authSlice";
import { webNotificationActivateApi } from "../../../Redux/Features/notificationReducer/notificationSlice";
import { emailNotificationActivateApi } from "../../../Redux/Features/notificationReducer/notificationSlice";

function NotificationSetting() {
  const dispatch = useDispatch();
  const { GetUserDetailsData } = useSelector((state) => state.Auth);
  const [disable, setDisable]=useState(false);


  let timer;

  const handleNotification = (e) => {
    setDisable(true);
    switch (e.target.name) {
      case "web":
        dispatch(
          webNotificationActivateApi({
            webNotification: e.target.checked,
          })
        )
          .unwrap()
          .then((result) => {
            if (result?.success) {
            timer =  setTimeout(() => {
                dispatch(GetUserDetailsApi()).unwrap();
                setDisable(false);
                clearTimeout(timer);
              }, 2000);
            }
          })
          .catch((error) => {
            console.log(error);
            setDisable(true);
          });
        break;
      case "email":
        dispatch(
          emailNotificationActivateApi({
            emailNotification: e.target.checked,
          })
        )
          .unwrap()
          .then((result) => {
            if (result?.success) {
             timer= setTimeout(() => {
                dispatch(GetUserDetailsApi()).unwrap();
                setDisable(false);
                clearTimeout(timer);
              }, 2000);
            }
          })
          .catch((error) => {
            console.log(error);
            setDisable(false);
          });
        break;
      default:
    }
  };
  return (
    <div
      className="tab-pane fade"
      id="notification"
      role="tabpanel"
      aria-labelledby="notification-tab"
    >
      <div className=" py-3 border-bottom">
        <div>
          <h4 className="fs-22 exMedium">Notification Settings</h4>
          <p className="fs-14 text-navy-light exLight mb-2 mb-md-0">
                  View and update your notification settings.
                </p>
        </div>
      </div>
      <div className="row py-3 border-bottom mx-1">
        <div className="col-md-6 col-10 px-0">
          <label className="form-check-label exMedium mb-2" for="webToggle">
            Web Notifications
          </label>
          {/* <p className="fs-14 mb-0 text-navy-light exLight">
            Get updates on your slack account.
          </p> */}
        </div>
        <div className="col-md-6 col-2 px-0">
          <div className="toggle-switch form-check form-switch float-end toggleinput">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="webToggle"
              checked={GetUserDetailsData?.data?.webNotification}
              name="web"
              onChange={handleNotification}
              disabled={disable}
            />
          </div>
        </div>
      </div>
      <div className="row py-3 border-bottom mx-1">
        <div className="col-md-6 col-10 px-0">
          <label className="form-check-label exMedium mb-2" for="emailToggle">
            Email Notifications
          </label>
          {/* <p className="fs-14 mb-0 text-navy-light exLight">
            Email are sent to test@gmail.com
          </p> */}
        </div>
        <div className="col-md-6 col-2 px-0">
          <div className="toggle-switch form-check form-switch float-end toggleinput">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="emailToggle"
              checked={GetUserDetailsData?.data?.emailNotification}
              name="email"
              onChange={handleNotification}
              disabled={disable}
            />
          </div>
        </div>
      </div>
      {/* <div className="row py-3 border-bottom mx-1">
        <div className="col-md-6 col-10 px-0">
          <label className="form-check-label exMedium mb-2" for="slackToggle">
            Slack Notifications
          </label>
        </div>
        <div className="col-md-6 col-2 px-0">
          <div className="toggle-switch form-check form-switch float-end toggleinput">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="slackToggle"
              // checked={GetUserDetailsData?.data?.emailNotification}
              name="slack"
              // onChange={handleNotification}
              // disabled={disable}
            />
          </div>
        </div>
      </div> */}
    </div>
  );
}
export default NotificationSetting;
