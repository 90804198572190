import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCheckAndNavigate } from '../../../hooks/checks/check-connection';
import { setLoggedIn, setUser } from '../../../Redux/Features/authReducer/authSlice';
import { SIGN_IN } from "../../../Routes/Constant";
import { successSwal } from "../../../Utils/constant/commonFunction";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const GoogleCallback = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const checkAndNavigate = useCheckAndNavigate();

  useEffect(() => {
    (async () => {
      const user = query.get('user');
      if (user) {
        try {
          const decodedUser = JSON.parse(decodeURIComponent(user));
          dispatch(setUser(decodedUser));
          dispatch(setLoggedIn(true));
          await successSwal("User logged in successfully")
          if (process.env.REACT_APP_AMPLITUDE) window.amplitude.setUserId(`uid_${decodedUser.id}`);
          checkAndNavigate();
        } catch (err) {
          console.error('Invalid user data:', err);
          navigate(SIGN_IN);
        }
      } else {
        navigate(SIGN_IN);
      }
    })();
  }, [query, navigate]);

  return null;
};

export default GoogleCallback;
