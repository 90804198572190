import {useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CONNECTION, LISTING, SIGN_IN } from "../../../Routes/Constant";
import {jwtDecode} from 'jwt-decode';
import { setToken, setUser } from "../../../Redux/Features/LocalStorage";
import Swal from "sweetalert2";
import { useDispatch } from 'react-redux';
import { CheckHoatawayConnection } from '../../../Redux/Features/authReducer/authSlice';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const GoogleCallback = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const dispatch= useDispatch();

  useEffect(() => {
    (async ()=>{ 
      const token = query.get('token');
    const user = query.get('user');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setToken(token);

        if (user) {
          const decodedUser = JSON.parse(decodeURIComponent(user));
          setUser(decodedUser);
        }

        if (decodedToken?.roleId === 1 || decodedToken?.roleId === 2) {
          Swal.fire({
            title: 'Success',
            text: 'User logged in successfully',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false,
            width: '300px',
            customClass: { popup: 'small-swal' }
          });
        }
        const { payload } = await dispatch(CheckHoatawayConnection());
      if (payload.validConnection === undefined) {
        navigate(CONNECTION);
      } else {
        navigate(LISTING);
      }
      } catch (err) {
        console.error('Invalid token:', err);
        navigate(SIGN_IN);
      }
    } else {
      navigate(SIGN_IN);
    } 
      })();
   
  }, [query, navigate]);

  return null;
};

export default GoogleCallback;

