import { useNavigate } from 'react-router-dom';
import { CONNECTION } from '../../Routes/Constant';
import { useCheckConnection } from '../queries/auth';

export const useCheckAndNavigate = () => {
  const checkConnection = useCheckConnection(false);
  const navigate = useNavigate();

  const checkAndNavigate = async () =>
    !(await checkConnection.refetch())?.data?.result && navigate(CONNECTION);

  return checkAndNavigate;
};