import React, { useState } from "react";
import { Form, ErrorMessage, Field, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import TextError from "../../Components/TextError";
import { GuestyAPISchema } from "../../Utils/Validation/Validation";
import { updateGuestyTokenByAdmin } from "../../Redux/Features/authReducer/authSlice";
import { Dialog } from "primereact/dialog";
import { setResetGuestyTokenModalVisible } from "../../Redux/Features/stateReducer/stateSlice";


function ResetGuestyToken() {
  const dispatch = useDispatch();
  const user = localStorage.getItem("ImpersonateUser");

  const [loading, setLoading] = useState(false);    

  const InitialValues = {
    guestyAccount_secret: "",
  };

  const [fieldType, setFieldType] = useState({
    key: "password",
    token: "password",
  });

  const { resetGuestyTokenModalVisible } = useSelector((state)=>state.State);

  function handleFieldFocus(value, value2) {

    if ("in" === value && "key" === value2) {
      setFieldType({ key: "text", token: "password" });
    } else if ("in" === value && "token" === value2) {
      setFieldType({ key: "passwprd", token: "text" });
    } else {
      setFieldType({ key: "password", token: "password" });
    }
  }

  const onSubmit = async (values) => {
    let timer;
    setLoading(true); 
    const data = {
        integrationToken: values.guestyAccount_secret,
    };
    try {
      await dispatch(updateGuestyTokenByAdmin(data))
        .unwrap()
        .then((result) => {
          if (result?.success) {
           timer = setTimeout(() => {
              dispatch(setResetGuestyTokenModalVisible(false));
              clearTimeout(timer);
            }, 1000)
          }
        })
        .finally(() => {
          setLoading(false); 
        });
    }
    catch (error) {
      setLoading(false);
      dispatch(setResetGuestyTokenModalVisible(false));
    };
  };

  const guestyHeader = (
    <>
     {user !== null && (
                <h5>Impersonated User: {user}</h5>
              )}
      <h4 className="color-black exMedium mb-3">Reset Guesty Token</h4>
    </>
  );

  return (
    <>
     <Dialog
        className="p-4 background-white rounded-17 mx-3 listing-setting"
        header={guestyHeader}
        visible={resetGuestyTokenModalVisible}
        style={{ maxWidth: "550px", width: "100%" }}
        onHide={() => {
          if (!resetGuestyTokenModalVisible) return;
          dispatch(setResetGuestyTokenModalVisible(false));
        }}
      >
      <div id="hostaway_connection">    
        <div id="wrapper"> 
            <Formik
              initialValues={InitialValues}
              validationSchema={GuestyAPISchema}
              onSubmit={onSubmit}
            >
              <Form>
                <div className="row py-4 mx-2 aligndiv">
                  <div className="col-md-6 px-0">
                    <label for="hostawayAccount_secret" className="exMedium">
                      Integration Token <span className="strictly">*</span>
                    </label>
                  </div>
                  <div className="col-md-6 px-0">
                    <Field
                      type={fieldType.token}
                      className="w-100 form-control form-control-lg input-lg rounded-pill color-dark "
                      id="guestyAccount_secret"
                      placeholder="Enter Integration Token"
                      name="guestyAccount_secret"
                      onFocus={(e) => {
                        handleFieldFocus("in", "token");
                      }}
                      onBlur={(e) => {
                        handleFieldFocus("out", "key");
                      }}
                    />
                    <ErrorMessage name="guestyAccount_secret" component={TextError}
                    />
                  </div>
                </div>
    
                    <div className="login-card-bottom mt-4 text-end">
                      <button
                        type="submit"
                        className="btn btn-lg button-purple rounded-pill ms-auto mt-2"
                        disabled={loading} 
                      >
                        {loading ? (
                          <span
                            className="spinner-border spinner-border-sm me-2 text-dark"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Reset Guesty Token"
                        )}
                      </button>
                    </div>
              </Form>
            </Formik>
        </div>
      </div>
      </Dialog> 
    </>
  );
}

export default ResetGuestyToken;
