import React from "react";
import Header from "../../Layouts/HeaderPage";
import SideBar from "../../Layouts/SideBar";
import AllUpdatedHistory from "../../Components/AllUpdatedHistory";
import { BreadCrumb } from "primereact/breadcrumb";
import {
  LISTING,
  PROPERTY_DETAILS,
  UPDATED_HISTORY,
} from "../../Routes/Constant";

function UpdatedHistory() {
  const items = [
    { label: "Listing", url: LISTING },
    { label: "Listing Details", url: PROPERTY_DETAILS },
    { label: "Updated History", url: UPDATED_HISTORY },
  ];
  const home = { icon: "pi pi-home", url: "/" };

  return (
    <>
      <div id="updated_history">
        <Header />
        <div id="wrapper">
          <SideBar />
          <section id="content-wrapper">
            <BreadCrumb
              model={items}
              home={home}
              className="border-0 text-decoration-none mb-2"
            />
            <h2 className=" content-title mb-0 exDemiBold mb-2">
            All Listing Updates
            </h2>
            {/* <p className="color-muted mb-4">
              Lorem ipsum dolor sit amet, consectetur.
            </p> */}
            <AllUpdatedHistory />
          </section>
        </div>
      </div>
    </>
  );
}

export default UpdatedHistory;
