import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getToken } from "../../Redux/Features/LocalStorage";
import { DASHBOARD } from "../Constant";

const CheckAuthRoutes = () => {
  const isAuth = getToken();

  return <>{!isAuth ? <Outlet /> : <Navigate to={DASHBOARD} />}</>;
};

export default CheckAuthRoutes;
