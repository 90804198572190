import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ForgetPass } from "../../../Redux/Features/authReducer/authSlice";
import { ErrorMessage, Field, Form, Formik } from "formik";
import TextError from "../../../Components/TextError/index";
import { ResetPasswordSchema } from "../../../Utils/Validation/Validation";
import { NavLink, useNavigate } from "react-router-dom";
import { RESET_PASSWORD, SIGN_IN } from "../../../Routes/Constant";
import { ToastContainer } from "react-toastify";
import { successToast } from "../../../Utils/constant/commonFunction";
function ForgotPassword() {
  const [loading, setLoading] = useState(false); // State for loading indicator

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const InitialValues = {
    email: "",
  };
 
  let timer;
  const handleSubmit = (values, { resetForm }) => {
    setLoading(true); // Start loading indicator
    const data = {
      email: values.email,
    };
    dispatch(ForgetPass(data))
      .unwrap()
      .then((result) => {
        if (result?.success) {
          successToast(result.message);
        timer =  setTimeout(() => {
            navigate(RESET_PASSWORD);
            clearTimeout(timer);
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false); // Stop loading indicator
        // resetForm();
      });
    // resetForm();
  };

  return (
    <>
      <div class="container-fluid h-100">
        <section id="forgot_page" class="h-100">
          <ToastContainer />
          <div class="row h-100 ">
            <div class="col-lg-6 px-0 h-100">
              <div class="left-box w-100 h-100 text-center position-relative">
                <div class="forgot-arrow">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Assets/images/client_images/logo_icon/Logo icon purple.png"
                    }
                    class="img-fluid"
                    alt="..."
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6 px-0 h-100">
              <div class="right-box h-100 display-center text-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/Assets/images/client_images/horizontal_logo/Logo-white.png"
                  }
                  className="img-fluid w-25 text-center py-5 position-absolute top-0 d-md-none"
                  alt=""
                />
                <div class="inner-box m-auto h-auto ">
                  <h2 class="text-white mb-5 ">Forgot your password?</h2>
                  <Formik
                    initialValues={InitialValues}
                    validationSchema={ResetPasswordSchema}
                    onSubmit={handleSubmit}
                  >
                    <Form>
                      <div class="mb-1">
                        <div class="input-group">
                          <span
                            class="input-group-text bg-transparent border-end-0 input-border"
                            id="basic-addon1"
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/Assets/images/icons/email.svg"
                              }
                              alt=""
                            />
                          </span>
                          <Field
                            type="email"
                            className="form-control bg-transparent border-start-0 rounded-0 py-3 input-border ps-0"
                            id="loginEmail"
                            name="email"
                            placeholder="E-mail address"                  
                          />
                        </div>
                      </div>
                      <ErrorMessage name="email" component={TextError} />
                      <button
                        type="submit"
                        class="btn button-light btn-lg form-control rounded-pill mt-4 mb-5"
                        disabled={loading} // Disable button while loading
                      >
                        {loading ? (
                          <span
                            className="spinner-border spinner-border-sm me-2 text-dark"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Next"
                        )}
                      </button>
                    </Form>
                  </Formik>
                  <NavLink
                    to={SIGN_IN}
                    className="text-secondary text-decoration-none pb-5 mb-5"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/Assets/images/icons/left_arrow.svg"
                      }
                      alt=""
                      class="pe-2 "
                    />
                    Go back to login
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default ForgotPassword;
