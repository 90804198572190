import React from "react";
import ReactDOM from "react-dom/client";
import { PrimeReactProvider} from "primereact/api";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primeicons/primeicons.css";
import App from "./App";
import "../src/Assets/css/style.css";
import "../src/Assets/css/responsive.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "react-toastify/dist/ReactToastify.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./Redux/Store";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <PrimeReactProvider>
    <Provider store={store}>
        <App />
    </Provider>
  </PrimeReactProvider>
);

reportWebVitals();
