import * as Yup from "yup";
import {
  INVALID_EMAIL,
  REQUIRED_FIELD,
  EMAIL_REQUIRED_FIELD,
  PASSWORD_REQUIRED_FIELD,
  MIN_PASSWORD,
  MAX_PASSWORD,
  RE_PASSWORD,
  FIRST_NAME,
  PHONE_NUMBER,
  PASSWORD_SPECIAL_CHARACTER,
  PASSWORD_NUMBER,
  PASSWORD_lOWWER,
  PASSWORD_UPPER,
  MAX_LENGTH,
  EXTRA_TRIM,
  MIN_OTP_LENGTH,
  MAX_OTP_LENGTH,
  TERMSANDCOND,
  FIRST_NAME_REQUIRED_FIELD,
  PHONE_NUMBER_REQUIRED_FIELD,
  PHONE_NUMBER_MAX,
  OTP_REQUIRED_FIELD,
  NEW_PASSWORD_REQUIRED_FIELD,
  CONFIRM_PASSWORD_REQUIRED_FIELD,
  OLD_PASSWORD_REQUIRED_FIELD,
  ACCOUNT_ID_REQUIRED_FIELD,
  ACCOUNT_TOKEN_REQUIRED_FIELD,
  MIN_BILLING_ADDRESS,
  MAX_BILLING_ADDRESS,
  ADDRESS_REQUIRED_FIELD,
  CITY_REQUIRED_FIELD,
  STATE_REQUIRED_FIELD,
  COUNTRY_REQUIRED_FIELD,
  PINCODE_REQUIRED_FIELD,
  CONSUMER_REQUIRED_FIELD,
  CONSUMER_ACCOUNT_REQUIRED_FIELD,
  PAYMENT_METHOD_REQUIRED_FIELD,
  SUBSCRIPTION_TYPE_REQUIRED_FIELD,
  PROPERTY_LIMIT_REQUIRED_FIELD
} from "./ValidationConstant";
import Regex from "./Regex";

// sign up form step 1
export const SignUpSchema = Yup.object({
  firstName: Yup.string()
    .trim(EXTRA_TRIM)
    .strict(true)
    .min(3, FIRST_NAME)
    .required(FIRST_NAME_REQUIRED_FIELD),
  // lastName: Yup.string()
  //   .trim(EXTRA_TRIM)
  //   .strict(true)
  //   .min(3, LAST_NAME)
  //   .required(LAST_NAME_REQUIRED_FIELD),
  email: Yup.string()
    .trim(EXTRA_TRIM)
    .strict(true)
    .email(INVALID_EMAIL)
    .required(EMAIL_REQUIRED_FIELD),
  password: Yup.string()
    .strict(true)
    .trim(EXTRA_TRIM)
    .min(8, MIN_PASSWORD)
    .max(16, MAX_PASSWORD)
    .required(PASSWORD_REQUIRED_FIELD)
    .matches(Regex.UPPER_REGEX, PASSWORD_UPPER)
    .matches(Regex.LOWER_REGEX, PASSWORD_lOWWER)
    .matches(Regex.NUMBER_REGEX, PASSWORD_NUMBER)
    .matches(Regex.SPCL_CHRCT_REGEX, PASSWORD_SPECIAL_CHARACTER),
  phoneNumber: Yup.string()
    .matches(/^[\d\+\-]*$/, 'Phone number must be only digits, plus signs, or dashes')
    .required(PHONE_NUMBER_REQUIRED_FIELD)
    .min(10, PHONE_NUMBER)
    .max(14, PHONE_NUMBER_MAX),
  termsCond: Yup.boolean().oneOf([true], TERMSANDCOND)
});

export const SignInSchema = Yup.object({
  email: Yup.string()
    .trim(EXTRA_TRIM)
    .strict(true)
    .email(INVALID_EMAIL)
    .required(EMAIL_REQUIRED_FIELD),
  password: Yup.string()
    .trim(EXTRA_TRIM)
    .min(8, MIN_PASSWORD)
    .max(16, MAX_PASSWORD)
    .required(PASSWORD_REQUIRED_FIELD),
});

export const ResetPasswordSchema = Yup.object({
  email: Yup.string()
    .trim(EXTRA_TRIM)
    .strict(true)
    .email(INVALID_EMAIL)
    .required(EMAIL_REQUIRED_FIELD)
});
export const HostawayAPISchema = Yup.object({
  hostawayAccountId: Yup.string()
    .trim(EXTRA_TRIM)
    .strict(true)
    .required(ACCOUNT_ID_REQUIRED_FIELD),
  hostawayAccount_secret: Yup.string()
    .trim(EXTRA_TRIM)
    .strict(true)
    .required(ACCOUNT_TOKEN_REQUIRED_FIELD),
});

export const GuestyAPISchema = Yup.object({
  // guestyAccountId: Yup.string()
  //   .trim(EXTRA_TRIM)
  //   .strict(true)
  //   .required(ACCOUNT_ID_REQUIRED_FIELD),
  guestyAccount_secret: Yup.string()
    .trim(EXTRA_TRIM)
    .strict(true)
    .required(ACCOUNT_TOKEN_REQUIRED_FIELD),
});

export const ForgetPasswordSchema = Yup.object({
  otp: Yup.string()
    .trim(EXTRA_TRIM)
    .strict(true)
    .required(OTP_REQUIRED_FIELD),

  newPassword: Yup.string()
    .trim(EXTRA_TRIM)
    .strict(true)
    .required(NEW_PASSWORD_REQUIRED_FIELD)
    .matches(Regex.UPPER_REGEX, PASSWORD_UPPER)
    .matches(Regex.LOWER_REGEX, PASSWORD_lOWWER)
    .matches(Regex.NUMBER_REGEX, PASSWORD_NUMBER)
    .matches(Regex.SPCL_CHRCT_REGEX, PASSWORD_SPECIAL_CHARACTER)
    .matches(Regex.MAX_TEN_DIGIT_REGEX, MIN_PASSWORD),

  conPassword: Yup.string()
    .trim(EXTRA_TRIM)
    .strict(true)
    .required(CONFIRM_PASSWORD_REQUIRED_FIELD)
    .oneOf([Yup.ref("newPassword"), null], RE_PASSWORD),
});

export const AddProfileSchema = Yup.object({
  name: Yup.string()
    .trim(EXTRA_TRIM)
    .strict(true)
    .min(3, FIRST_NAME)
    .max(50, MAX_LENGTH)
    .required(REQUIRED_FIELD),
});
export const DeviceOtpSchema = Yup.object({
  otp: Yup.string()
    .trim(EXTRA_TRIM)
    .strict(true)
    .min(6, MIN_OTP_LENGTH)
    .max(6, MAX_OTP_LENGTH)
    .required(REQUIRED_FIELD),
});
export const EditUserSchema = Yup.object().shape({
  image: Yup.mixed().nullable(),
  fname: Yup.string()
    .trim(EXTRA_TRIM)
    .strict(true)
    .min(3, FIRST_NAME)
    .max(50, MAX_LENGTH)
    .required(FIRST_NAME_REQUIRED_FIELD),
      phoneNumber: Yup.string()
    .required(PHONE_NUMBER_REQUIRED_FIELD)
    .min(10, PHONE_NUMBER),

  // image: Yup.mixed().required('An image is required')
});

export const CreateCouponSchema = Yup.object().shape({
  couponCode: Yup.string()
    .trim('Remove extra spaces.')
    .strict(true)
    .min(3, 'Code must be at least 3 characters.')
    .max(50, 'Code must be at maximum 50 characters.')
    .required('Coupon code is required.'),
  // discountType: Yup.string()
  //   .required('Discount type is required.'),
  // discountValue: Yup.number().test(
  //   'discountValue',
  //   'Invalid discount value. Enter 0-100 for percentage or up to 5 digits for amount.',
  //   function (value) {
  //     const { parent } = this;
  //     if (parent.discountType === 'percentage') {
  //       return value >= 0 && value <= 100;
  //     } else {
  //       const stringValue = String(value);
  //       return stringValue?.length <= 5 && !isNaN(value);
  //     }
  //   },
  // ),
  expiryDate: Yup.date()
    .required('Expiration date is required.')
    .test('is-future-date', 'Expiration date must be in the future.', function (value) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return value >= today;
    })
    .typeError('Invalid expiration date.'),
    minValue: Yup.number()
    .min(1, 'The number must be at least 1')
    .required('Minimum field is required'),
});


export const CHANGEPASSWORD = Yup.object({
  oldPassword: Yup.string()
    .trim(EXTRA_TRIM)
    .min(8, MIN_PASSWORD)
    .max(16, MAX_PASSWORD)
    .required(OLD_PASSWORD_REQUIRED_FIELD),
  newPassword: Yup.string()
    .trim(EXTRA_TRIM)
    .strict(true)
    .min(8, MIN_PASSWORD)
    .max(16, MAX_PASSWORD)
    .required(NEW_PASSWORD_REQUIRED_FIELD)
    .matches(Regex.MAX_TEN_DIGIT_REGEX, MIN_PASSWORD)
    .matches(Regex.UPPER_REGEX, PASSWORD_UPPER)
    .matches(Regex.LOWER_REGEX, PASSWORD_lOWWER)
    .matches(Regex.NUMBER_REGEX, PASSWORD_NUMBER)
    .matches(Regex.SPCL_CHRCT_REGEX, PASSWORD_SPECIAL_CHARACTER),
  confirmPassword: Yup.string()
    .trim(EXTRA_TRIM)
    .strict(true)
    .required(CONFIRM_PASSWORD_REQUIRED_FIELD)
    .oneOf([Yup.ref("newPassword"), null], RE_PASSWORD),
});

export const DescriptionSchema = Yup.object({
  includeDescription: Yup.string()
    .max(500, 'Must be less than or equal 500 characters')
    .trim(EXTRA_TRIM),
  excludeDescription: Yup.string()
    .trim(EXTRA_TRIM)
    .max(500, 'Must be less than or equal 500 characters')
});

export const propertyFilterSchema = Yup.object({
  propertyStatus: Yup.string(),
  dataSource: Yup.string(),
  country: Yup.string(),
  city: Yup.string()
});

export function formatPhoneNumber(value) {
  const phoneNumber = value.replace(Regex.PHONE_NMBR_REGEX, "");
  return phoneNumber.replace(Regex.US_PHONE_REGEX, "($1)$2-$3");
}
export function numberFormat(value) {
  return value.replace(/\D/g, "");
}
export function cardExpiryFormat(value) {
  return value.replace(Regex.CARD_EXPIRY_REGEX);
}

export const EditBillingInformationSchema = Yup.object().shape({
  companyName: Yup.string()
    .strict(true),

  billingAddress: Yup.string()
    .strict(true)
    .min(3, MIN_BILLING_ADDRESS)
    .max(50, MAX_BILLING_ADDRESS)
    .required(ADDRESS_REQUIRED_FIELD),
  billingCity: Yup.string()
    .strict(true)
    .required(CITY_REQUIRED_FIELD),
  billingState: Yup.string()
    .strict()
    .required(STATE_REQUIRED_FIELD),
  billingCountry: Yup.string()
    .strict(true)
    .required(COUNTRY_REQUIRED_FIELD),
  billingPincode: Yup.number()
    .integer('Must be an integer')
    .typeError('Must be an integer')
    .required(PINCODE_REQUIRED_FIELD)
    .test('isInteger', 'Must be an integer', (value) => Number.isInteger(value)),
  companyVatNumber: Yup.string()
    .strict(true)
    .trim(EXTRA_TRIM),
  // .required(VAT_NUMBER_REQUIRED_FIELD),
});

export const PropertyLimitSchema = Yup.object().shape({
  propertyLimit: Yup.number()
    .integer('Must be an integer')
    .typeError('Must be an integer')
    .required(PROPERTY_LIMIT_REQUIRED_FIELD)
    .test('isInteger', 'Must be an integer', (value) => Number.isInteger(value)),
})

export const AddAccountDetailsSchema = Yup.object().shape({
  consumerName: Yup.string()
    .strict(true)
    .required(CONSUMER_REQUIRED_FIELD),
  consumerAccount: Yup.string()
    .strict(true)
    .required(CONSUMER_ACCOUNT_REQUIRED_FIELD),
  method: Yup.string()
    .strict(true)
    .required(PAYMENT_METHOD_REQUIRED_FIELD),
});

export const SelectSubscriptionSchema = Yup.object().shape({
  subscriptionType: Yup.string()
    .required(SUBSCRIPTION_TYPE_REQUIRED_FIELD),
});

