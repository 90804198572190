import React from "react";

function KeywordRetFreq() {

    return (
        <div
            className="tab-pane fade shadow-sm mt-4 rounded-17"
            id="keywordretrieval"
            role="tabpanel"
            aria-labelledby="keywordretrieval-tab"
        >
            <h2>Coming Soon</h2>
        </div>
    );
}

export default KeywordRetFreq;
