import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectSubscriptionSchema } from "../../../Utils/Validation/Validation";
import { ErrorMessage, Field, Form, Formik } from "formik";
import TextError from "../../../Components/TextError/index";
import { GetUserDetailsApi } from "../../../Redux/Features/authReducer/authSlice";
import { insertUserSubscriptionType } from "../../../Redux/Features/dashboard/dashboardSlice";

const FormWrapper = ({ loading, setFieldValue, }) => {
    const { GetUserDetailsData } = useSelector((state) => state.Auth);

    useEffect(() => {
        if (GetUserDetailsData?.data?.subscriptionType) {
            setFieldValue("subscriptionType", GetUserDetailsData?.data?.subscriptionType)
        }
    }, [GetUserDetailsData])

    return (
        <Form className="px-2">
            <div className="d-flex align-items-md-center justify-content-between py-3 border-bottom flex-md-row flex-column">
                <div>
                    <h4 className="fs-22 exMedium">Subscription Plan</h4>
                    <p className="fs-14 text-navy-light exLight mb-2 mb-md-0">
                        View and update your subscription plan.
                    </p>
                </div>
                <div>
                    <button
                        type="submit"
                        className="btn btn-lg button-navy rounded-pill mb-md-2"
                        disabled={loading}
                    >
                        {loading ? (
                            <span
                                className="spinner-border spinner-border-sm me-2 text-dark"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        ) : (
                            "Save Changes"
                        )}
                    </button>
                    <ErrorMessage name="subscriptionType" component={TextError} />
                </div>
            </div>
            <div className="p-3">
                <div className="inputlabel flex align-items-center mb-3">
                    <Field
                        type="radio"
                        name="subscriptionType"
                        value="monthly"
                    />
                    <label className="text-lg ml-2 gp-5">
                        Monthly
                    </label>
                </div>
                <div className="inputlabel flex align-items-center">
                    <Field
                        type="radio"
                        name="subscriptionType"
                        value="yearly"
                    />
                    <label className="text-lg ml-2 gp-5">
                        Yearly
                    </label>
                </div>
            </div>
        </Form>
    )
}

function SubscriptionPlan() {
    const { GetUserDetailsData } = useSelector((state) => state.Auth);
    const [loading, setLoading] = useState(false); 
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(GetUserDetailsApi());
    }, []);

    
    const initialValues = {
        subscriptionType: GetUserDetailsData?.data?.subscriptionType || ""
    };

    const handleSubmit = async (values) => {
        setLoading(true);
        const data = {
            subscriptionType: values.subscriptionType,
        };
        try {
            await dispatch(insertUserSubscriptionType(data))
                .unwrap()
                .then((result) => {
                    dispatch(GetUserDetailsApi())
                })
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };


    return (
        <div
            className="tab-pane fade shadow-sm mt-4 rounded-17"
            id="subscription"
            role="tabpanel"
            aria-labelledby="subscription-tab"
        >
            <Formik
                initialValues={initialValues}
                validationSchema={SelectSubscriptionSchema}
                onSubmit={handleSubmit}

            >
                {(formProps) => {
                    return (
                        <FormWrapper {...formProps} loading={loading} />
                    )
                }}
            </Formik>
        </div>
    );
}
export default SubscriptionPlan;
