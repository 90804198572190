import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { successToast } from "../../../Utils/constant/commonFunction";
import notificationService from "./notificationService"

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
  webNotificationActivateData: "",
  emailNotificationActivateData: "",
  fetchedNotifications: "",
  markAllRead: "",
  getNotificationInactiveByUserData:""
}

/* activate web notification  */
export const webNotificationActivateApi = createAsyncThunk(
    "webNotificationActivate",
    async (data, thunkAPI) => {
      try{
        return await notificationService.webNotificationActivateApi(data);
      } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

/* activate email notification  */
export const emailNotificationActivateApi = createAsyncThunk(
    "emailNotificationActivate",
    async (data, thunkAPI) => {
      try{
        return await notificationService.emailNotificationActivateApi(data);
      } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

/* fetch notification  */
export const fetchNotificationApi = createAsyncThunk(
    "getNotification",
    async (_, thunkAPI) => {
      try{
        return await notificationService.fetchNotificationApi();
      } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  /* mark all notification read */
export const markAllReadApi = createAsyncThunk(
  "markAllReadAndSingle",
  async (data, thunkAPI) => {
    try{
      return await notificationService.markAllReadApi(data);
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// manual delete from all notification
export const getNotificationInactiveByUser = createAsyncThunk(
  "getNotificationInactiveByUser",
  async (data, thunkAPI) => {
    try{
      return await notificationService.getNotificationInactiveByUser(data);
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message)
    }
  }
)

  /*  For Slice */
const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
      reset: (state) => {
        state.isSuccess = false;
        state.isError = false;
        state.isLoading = false;
        state.message = "";
      },    
    },
    extraReducers: (builder) => {
      builder

      //activate web notification
        .addCase(webNotificationActivateApi.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(webNotificationActivateApi.fulfilled, (state, { payload }) => {
          state.isError = false;
          state.isLoading = false;
          state.isSuccess = true;
          state.message = payload.message;
          successToast(payload.message)
          state.webNotificationActivateData = payload;       
        })
        .addCase(webNotificationActivateApi.rejected, (state, { payload }) => {
          state.isError = true;
          state.isLoading = false;
          state.isSuccess = false;
          state.message = payload.message;         
        })

      //activate email notification
        .addCase(emailNotificationActivateApi.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(emailNotificationActivateApi.fulfilled, (state, { payload }) => {
          state.isError = false;
          state.isLoading = false;
          state.isSuccess = true;
          state.message = payload.message;
          successToast(payload.message)
          state.emailNotificationActivateData = payload;       
        })
        .addCase(emailNotificationActivateApi.rejected, (state, { payload }) => {
          state.isError = true;
          state.isLoading = false;
          state.isSuccess = false;
          state.message = payload.message;         
        })

      //fetch email notification
        .addCase(fetchNotificationApi.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(fetchNotificationApi.fulfilled, (state, { payload }) => {
          state.isError = false;
          state.isLoading = false;
          state.isSuccess = true;
          state.message = payload.message;
          state.fetchedNotifications = payload;       
        })
        .addCase(fetchNotificationApi.rejected, (state, { payload }) => {
          state.isError = true;
          state.isLoading = false;
          state.isSuccess = false;
          state.message = payload.message;         
        })

      //mark all notification read
        .addCase(markAllReadApi.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(markAllReadApi.fulfilled, (state, { payload }) => {
          state.isError = false;
          state.isLoading = false;
          state.isSuccess = true;
          state.message = payload.message;
          state.markAllRead = payload;
        
        })
        .addCase(markAllReadApi.rejected, (state, { payload }) => {
          state.isError = true;
          state.isLoading = false;
          state.isSuccess = false;
          state.message = payload.message;
        })

        //manual delete all notification
        .addCase(getNotificationInactiveByUser.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getNotificationInactiveByUser.fulfilled, (state, { payload }) => {
          state.isError = false;
          state.isLoading = false;
          state.isSuccess = true;
          state.message = payload.message;
          successToast(payload.message);
          state.getNotificationInactiveByUserData = payload;       
        })
        .addCase(getNotificationInactiveByUser.rejected, (state, { payload }) => {
          state.isError = true;
          state.isLoading = false;
          state.isSuccess = false;
          state.message = payload.message;         
        })        
    },
});

export const { reset } = notificationSlice.actions;
export default notificationSlice.reducer;