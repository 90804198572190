import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import { setAutomationModalVisible, setSubscriptionModalVisible, setNewAutoChecked } from "../../Redux/Features/stateReducer/stateSlice";
import SubscriptionPaymentModal from "../../Components/Coupon/SubscriptionPaymentModal";

const PropertyListingModal = (props) => {

  const { handleAddList, selectedOrder, freelistingdata } = props
  const { automationModalVisible, freeListingAddButtonLoading } = useSelector((state) => state.State);
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(true);

  const handleCheckbox = (e) => {
    const { checked } = e?.target;
    setChecked(checked);
    dispatch(setNewAutoChecked(checked));
  }

  const callhandleAddList = () => {

    if (!freelistingdata == 0) {
      if (selectedOrder?.length <= `${freelistingdata}`) {
        handleAddList(checked);
      }
      else {
        dispatch(setSubscriptionModalVisible(true));
      }
    }
    else {
      dispatch(setSubscriptionModalVisible(true));
    }

  }

  const footerContent = (
    <div className="d-flex px-4 justify-content-between my-2" style={{ padding: "25px" }}>
      <div className="d-flex align-items-end justify-content-center inputcheck">
        <input
          className="form-check-input green-checkbox"
          type="checkbox"
          checked={checked}
          aria-label="..."
          onChange={(e) => handleCheckbox(e)}
        />

        <label className="mx-2">Automate Selected Listings</label>
      </div>

      <button
        className="btn button-purple rounded-pill font-normal me-4"
        disabled={freeListingAddButtonLoading}
        onClick={() => callhandleAddList()}
      >
        {freeListingAddButtonLoading ? (
          <span
            className="spinner-border spinner-border-sm me-2 text-dark"
            role="status"
            aria-hidden="true"
          ></span>
        ) : (
          "Add Listing"
        )}
      </button>

    </div>
  );

  const headerContent = (
    <div className="SmartListing">
      <h4> Smart Listing Automation</h4>
    </div>
  )

  return (
    <>
      <div className="">
        <Dialog
          header={headerContent}
          visible={automationModalVisible}
          style={{ width: "36vw", fontSize: "0.9rem" }}
          onHide={() => {
            if (!automationModalVisible) return;
            dispatch(setAutomationModalVisible(false));
            setChecked(true);
          }}
          footer={footerContent}
        >
          <div style={{ padding: "25px" }}>
            <p className="m-0">
              AutoRank leverages AI to enhance the visibility, performance, and
              appeal of your listings. Here's how it attracts more customers and
              increases views & bookings:
            </p>
            <div className="d-flex justify-content-center align-items-center mt-2">
              <ul className="dotted-list">
                <li className="d-flex mb-2">
                  <i
                    className="pi pi-circle-fill"
                    style={{
                      fontSize: "5px",
                      marginTop: "9px",
                      marginRight: "5px",
                    }}
                  />{" "}
                  <p className="SmartListingbold me-2">
                    <span className="spanname"> Automated Keyword Optimization - </span>
                    Ensures listings are packed with high-impact keywords to
                    boost search visibility.
                  </p>
                </li>

                <li className="d-flex mb-2">
                  <i
                    className="pi pi-circle-fill"
                    style={{
                      fontSize: "5px",
                      marginTop: "9px",
                      marginRight: "5px",
                    }}
                  />{" "}
                  <p className="SmartListingbold me-2">
                    {" "}
                    <span> Personalized Descriptions - </span>
                    Crafts tailored descriptions that resonate with potential
                    guests or buyers, creating a stronger appeal.
                  </p>
                </li>
                <li className="d-flex mb-2">
                  <i
                    className="pi pi-circle-fill"
                    style={{
                      fontSize: "5px",
                      marginTop: "9px",
                      marginRight: "5px",
                    }}
                  />{" "}
                  <p className="SmartListingbold me-2">
                    <span className="SmartListingbold">Competitor Tracking -</span>
                    Monitors top competing listings, allowing you to stay ahead
                    of trends and adapt to changes in listing demand.
                  </p>
                </li>
              </ul>
            </div>
            <p>
              These automated features help you stay competitive and drive
              bookings with minimal manual effort.
            </p>
          </div>
        </Dialog>
        {/* <SubscriptionPaymentModal /> */}

      </div>
    </>
  );
};

export default PropertyListingModal;
