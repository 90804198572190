import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Layouts/HeaderPage";
import SideBar from "../../Layouts/SideBar";
import { Dropdown } from 'primereact/dropdown';
import {
  automatedListingDataApi,
  reviewScoreDataApi,
  bookingPaceMomentumDataApi,
  totalRevenueforMonthDataApi,
  bookingPaceMomentumAirbnbDataApi,
  topBookingSitesDataApi,
  totalRevenueMonthAndYearDataApi,
  getAverageReviewScore,
  automatedAndUnautomatedListingData,
  userDashBoardData
} from "../../Redux/Features/dashboard/dashboardSlice";
import { setHostawayModalVisible, setGuestyModalVisible } from "../../Redux/Features/stateReducer/stateSlice";
import DoughnutChart from "../../Components/dashboard/DoughnutChart";
import DashboardCard from "../../Components/dashboard/DashboardCard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

function Dashboards() {

  const [selectedDay, setSelectedDay] = useState({name: "Monthly", code: "monthly" });

  const dispatch = useDispatch();

  const {
    automatedListingData,
    reviewScoreData,
    bookingPaceMom,
    totalRevenueforMonth,
    automatedAndUnautomatedListing,
    userDashBoardsData
  } = useSelector((state) => state.Dashboard);

  const days = [
    { name: 'Monthly', code: 'monthly' },
    { name: 'Weekly', code: 'weekly' },

  ];

  useEffect(() => {
    dispatch(automatedListingDataApi());
    dispatch(reviewScoreDataApi());
    dispatch(bookingPaceMomentumDataApi());
    dispatch(totalRevenueforMonthDataApi());
    dispatch(bookingPaceMomentumAirbnbDataApi());
    dispatch(topBookingSitesDataApi());
    dispatch(totalRevenueMonthAndYearDataApi());
    dispatch(getAverageReviewScore());
    dispatch(automatedAndUnautomatedListingData());
    dispatch(setGuestyModalVisible(false));
    dispatch(setHostawayModalVisible(false));
  }, []);

  useEffect(() => {
    dispatch(userDashBoardData({ dateRange: selectedDay?.code }))
  }, [selectedDay]);

  const header = (
    <div>
      <h5 className="exDemiBold fs-22 " style={{ padding: "20px", margin: "unset", color: "#000" }}>Top Performing Listings</h5>
    </div>
  );

  const keywordBodyTemplate = (rowData) => {
    return (
      <>
        <div className="d-flex align-items-center">
          <p>
            {rowData?.keywords || "N/A"}
          </p>
        </div>
      </>
    );
  };

  const revenueBodyTemplate = (rowData) => {
    return (
      <>
        <div className="d-flex align-items-center">
          <p>
            $ {rowData?.totalRevenue}
          </p>
        </div>
      </>
    );
  };

  const propertyBodyTemplate = (rowData) => {

    return (
      <React.Fragment>
        <div className="d-flex align-items-center">
          <img
            className="propertyImg rounded-2 me-2"
            src={
              rowData?.thumbnailUrl ||
              process.env.PUBLIC_URL + "/Assets/images/house_placeholder.png"
            }
            onError={(e) => {
              e.target.src =
                process.env.PUBLIC_URL + "/Assets/images/house_placeholder.png"; // replace with a default image
            }}
            alt="Not available"
          />
          <p className="mb-0 ">{rowData?.name}</p>
        </div>
      </React.Fragment>
    );
  }

  return (
    <>
      <div id="dashboard">
        <div id="wrapper">
          <SideBar />
          <Header />

          <section id="content-wrapper">
            <div className="welcome-user ">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h2 className=" content-title mb-0 exDemiBold mb-3">Dashboard</h2>

                <div style={{display: "flex", gap:"12px"}}>
                <p style={{marginTop:"10px", color:"#746e6e"}}>Booking are up 50% in the last 30 days!</p>
                <div className="card flex justify-content-center">
                  <Dropdown value={selectedDay} onChange={(e) => setSelectedDay(e.value)} options={days} optionLabel="name"
                    placeholder="Select a Days" className="w-full md:w-14rem" />
                </div>
                </div>

              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="db-charts">
                    {" "}
                    <DoughnutChart
                      automatedAndUnautomatedListing={
                        userDashBoardsData?.userDataPayload
                      }
                      className="h-100"
                    />
                  </div>

                  <div className="dashboard_cards row mb-4 gy-4 mt-3">
                    {/* <DashboardCard
                      imgURL={
                        process.env.PUBLIC_URL +
                        "/Assets/images/icons/automatization.svg"
                      }
                      cardHeading={
                        automatedListingData?.data?.totalAutomatedListing || 0
                      }
                      cardContent="Automated Listings"
                      percent={`${automatedListingData?.data?.totalPercentage || 0
                        }%`}
                      duration="(Past 30 days)"
                      bgColor={"rgba(137,255,212,.2)"}
                      IconOuter={"var(--mint-40)"}
                    />
                    <DashboardCard
                      imgURL={
                        process.env.PUBLIC_URL +
                        "/Assets/images/icons/review_score.svg"
                      }
                      cardHeading={
                        reviewScoreData?.data?.sumOfUpdatedReviewScoreData || 0
                      }
                      cardContent="Review Score"
                      percent={`${reviewScoreData?.data?.totalPercentage || 0}%`}
                      duration="(Past 30 days)"
                      bgColor={"var(--lime-20)"}
                      IconOuter={"var(--lime-40)"}
                    />
                    <DashboardCard
                      imgURL={
                        process.env.PUBLIC_URL + "/Assets/images/icons/momentum.svg"
                      }
                      cardHeading={bookingPaceMom?.percentageLastTwoDays || 0}
                      cardContent="Booking Pace Momentum"
                      percent={`${bookingPaceMom?.totalBookingPaceData || 0}%`}
                      duration="(Past 02 days)"
                      bgColor={"var(--purple-15)"}
                      IconOuter={"var(--purple-25)"}
                    />
                    <DashboardCard
                      imgURL={
                        process.env.PUBLIC_URL +
                        "/Assets/images/icons/account_balance_wallet.svg"
                      }
                      cardHeading={totalRevenueforMonth?.totalRevenue || 0}
                      cardContent="This Months Revenue"
                      percent={`${totalRevenueforMonth?.percentage || 0}%`}
                      duration="(Past 30 days)"
                      bgColor={"var(--navy-10)"}
                      IconOuter={"var(--navy-15)"}
                    /> */}

                    <div class="col-5 commoncard" style={{ background: '#fcffe8', color: '#9aaa35', marginRight: "35px" }}>
                      <div class="card-body ps-2 p-0 mb-2">
                        <h4 class="card-title fs-1 fw-bold exBold text-center">0</h4>
                        <p class="card-text mb-2 exMedium fs-6 text-center">Keywords Added</p>
                      </div>
                    </div>

                    <div class="col-5 commoncard" style={{ background: '#f0fff9', color: '#0fb46e', marginRight: "35px" }}>
                      <div class="card-body ps-2 p-0 mb-2">
                        <h4 class="card-title fs-1 fw-bold exBold text-center">{userDashBoardsData?.userDataPayload?.optmisedData}</h4>
                        <p class="card-text mb-2 exMedium fs-6 text-center">Total Optimizations</p>
                      </div>
                    </div>

                    <div class="col-5 commoncard" style={{ background: '#f4f2ff', color: '#8d7cf5', marginRight: "35px" }}>
                      <div class="card-body ps-2 p-0 mb-2">
                        <h4 class="card-title fs-1 fw-bold exBold text-center">{userDashBoardsData?.userDataPayload?.totalEventDataCount}</h4>
                        <p class="card-text mb-2 exMedium fs-6 text-center">Events Added</p>
                      </div>
                    </div>

                    <div class="col-5 commoncard" style={{ background: '#f5f5f5', color: '#4d4d50', marginRight: "35px" }}>
                      <div class="card-body ps-2 p-0 mb-2">
                        <h4 class="card-title fs-1 fw-bold exBold text-center">{userDashBoardsData?.userDataPayload?.totalAttractionDataCount}</h4>
                        <p class="card-text mb-2 exMedium fs-6 text-center">Attractions Added</p>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <h2 className="card-title fs-1 fw-bold blink" style={{ color: '#7e6cf4' }}>Coming Soon</h2>
                </div>
              </div>

              <DataTable style={{ marginBottom: "unset" }}
                className="editNick mt-4"
                value={userDashBoardsData?.userDataPayload?.topPerformingLsiting}
                dataKey="id"
                tableStyle={{ minWidth: '50rem' }}
                responsiveLayout="scroll"
                size="small"
                showGridlines
                removableSort
                paginator
                rows={6}
                rowsPerPageOptions={[6, 20, 30, 50]}
                paginatorTemplate="RowsPerPageDropdown  PrevPageLink CurrentPageReport NextPageLink "
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                header={header}
              >
                <Column
                  field="name"
                  header="Property"
                  body={propertyBodyTemplate}
                  sortable
                ></Column>
                <Column
                  field="optimisationCount"
                  header="Optimizations"
                  sortable
                ></Column>
                <Column
                  field="keywords"
                  header="Keywords"
                  body={keywordBodyTemplate}
                  sortable
                ></Column>
                <Column
                  field="eventCount"
                  header="Events"
                  sortable
                ></Column>
                <Column
                  field="attractionCount"
                  header="Attractions"
                  sortable
                ></Column>
                <Column
                  field="totalNumberOfBooking"
                  header="Bookings"
                  sortable
                ></Column>
                <Column
                  field="totalRevenue"
                  header="Revenue"
                  body={revenueBodyTemplate}
                  sortable
                ></Column>
              </DataTable>

              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12 mt-4 gy-4">
                  <div className="monthrevenue dash_card card border-0 shadow-sm rounded-edge-17 h-100 p-3">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/Assets/images/icons/account_balance_wallet.svg"}
                          alt="account_balance_wallet Icon"
                          style={{ width: '30px', marginRight: '10px' }}
                        />
                        <p className="mt-5" style={{ fontSize: '16px', color: '#202744' }}>
                          <svg
                            className="me-1"
                            width="14"
                            height="16"
                            viewBox="0 0 14 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_699_2125)">
                              <path
                                d="M2.70763 7.2866C2.89516 7.4756 3.14947 7.58178 3.41463 7.58178C3.6798 7.58178 3.9341 7.4756 4.12163 7.2866L5.91463 5.47894L5.91463 12.1188C5.91463 12.3862 6.01999 12.6426 6.20753 12.8317C6.39506 13.0207 6.64942 13.127 6.91463 13.127C7.17985 13.127 7.4342 13.0207 7.62174 12.8317C7.80927 12.6426 7.91463 12.3862 7.91463 12.1188L7.91463 5.47894L9.70763 7.2866C9.89623 7.47025 10.1488 7.57186 10.411 7.56957C10.6732 7.56727 10.924 7.46124 11.1095 7.27432C11.2949 7.08739 11.4 6.83453 11.4023 6.57019C11.4046 6.30585 11.3038 6.05119 11.1216 5.86104L7.62163 2.33243C7.4341 2.14343 7.1798 2.03725 6.91463 2.03725C6.64947 2.03725 6.39516 2.14343 6.20763 2.33243L2.70763 5.86104C2.52016 6.0501 2.41485 6.30649 2.41485 6.57382C2.41485 6.84115 2.52016 7.09754 2.70763 7.2866Z"
                                fill="#202744"
                              />
                            </g>

                            <defs>
                              <clipPath id="clip0_699_2125">
                                <rect
                                  width="12"
                                  height="12.0981"
                                  fill="white"
                                  transform="translate(12.9141 13.6309) rotate(-180)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          {userDashBoardsData?.userDataPayload?.revenuePercentage}%</p>
                      </div>

                      <div>
                        <h3 className="card-title fs-1 fw-bold exBold" style={{ margin: 0 }}>${userDashBoardsData?.userDataPayload?.revenu}</h3>
                        <p className="mt-4" style={{ fontSize: '16px', color: '#202744' }}>This Months Revenue</p>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12 mt-4 gy-4">
                  <div className="monthrevenue dash_card card border-0 shadow-sm rounded-edge-17 h-100 p-3">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/Assets/images/icons/review_score.svg"}
                          alt="review_score Icon"
                          style={{ width: '30px', marginRight: '10px' }}
                        />
                        <p className="mt-5" style={{ fontSize: '16px', color: '#202744' }}>
                          <svg
                            className="me-1"
                            width="14"
                            height="16"
                            viewBox="0 0 14 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_699_2125)">
                              <path
                                d="M2.70763 7.2866C2.89516 7.4756 3.14947 7.58178 3.41463 7.58178C3.6798 7.58178 3.9341 7.4756 4.12163 7.2866L5.91463 5.47894L5.91463 12.1188C5.91463 12.3862 6.01999 12.6426 6.20753 12.8317C6.39506 13.0207 6.64942 13.127 6.91463 13.127C7.17985 13.127 7.4342 13.0207 7.62174 12.8317C7.80927 12.6426 7.91463 12.3862 7.91463 12.1188L7.91463 5.47894L9.70763 7.2866C9.89623 7.47025 10.1488 7.57186 10.411 7.56957C10.6732 7.56727 10.924 7.46124 11.1095 7.27432C11.2949 7.08739 11.4 6.83453 11.4023 6.57019C11.4046 6.30585 11.3038 6.05119 11.1216 5.86104L7.62163 2.33243C7.4341 2.14343 7.1798 2.03725 6.91463 2.03725C6.64947 2.03725 6.39516 2.14343 6.20763 2.33243L2.70763 5.86104C2.52016 6.0501 2.41485 6.30649 2.41485 6.57382C2.41485 6.84115 2.52016 7.09754 2.70763 7.2866Z"
                                fill="#202744"
                              />
                            </g>

                            <defs>
                              <clipPath id="clip0_699_2125">
                                <rect
                                  width="12"
                                  height="12.0981"
                                  fill="white"
                                  transform="translate(12.9141 13.6309) rotate(-180)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          {userDashBoardsData?.userDataPayload?.reviewScorePercentage}%</p>
                      </div>

                      <div>
                        <h3 className="card-title fs-1 fw-bold exBold" style={{ margin: 0 }}>{userDashBoardsData?.userDataPayload?.sumOfUpdatedReviewScoreData}</h3>
                        <p className="mt-4" style={{ fontSize: '16px', color: '#202744' }}>Avg, Review Score </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12 mt-4 gy-4">
                  <div className="monthrevenue dash_card card border-0 shadow-sm rounded-edge-17 h-100 p-3">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/Assets/images/icons/momentum.svg"}
                          alt="momentum Icon"
                          style={{ width: '30px', marginRight: '10px' }}
                        />
                        <p className="mt-5" style={{ fontSize: '16px', color: '#202744' }}>
                          <svg
                            className="me-1"
                            width="14"
                            height="16"
                            viewBox="0 0 14 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_699_2125)">
                              <path
                                d="M2.70763 7.2866C2.89516 7.4756 3.14947 7.58178 3.41463 7.58178C3.6798 7.58178 3.9341 7.4756 4.12163 7.2866L5.91463 5.47894L5.91463 12.1188C5.91463 12.3862 6.01999 12.6426 6.20753 12.8317C6.39506 13.0207 6.64942 13.127 6.91463 13.127C7.17985 13.127 7.4342 13.0207 7.62174 12.8317C7.80927 12.6426 7.91463 12.3862 7.91463 12.1188L7.91463 5.47894L9.70763 7.2866C9.89623 7.47025 10.1488 7.57186 10.411 7.56957C10.6732 7.56727 10.924 7.46124 11.1095 7.27432C11.2949 7.08739 11.4 6.83453 11.4023 6.57019C11.4046 6.30585 11.3038 6.05119 11.1216 5.86104L7.62163 2.33243C7.4341 2.14343 7.1798 2.03725 6.91463 2.03725C6.64947 2.03725 6.39516 2.14343 6.20763 2.33243L2.70763 5.86104C2.52016 6.0501 2.41485 6.30649 2.41485 6.57382C2.41485 6.84115 2.52016 7.09754 2.70763 7.2866Z"
                                fill="#202744"
                              />
                            </g>

                            <defs>
                              <clipPath id="clip0_699_2125">
                                <rect
                                  width="12"
                                  height="12.0981"
                                  fill="white"
                                  transform="translate(12.9141 13.6309) rotate(-180)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          {userDashBoardsData?.userDataPayload?.percentageOfBookingPaceMomentum}%</p>
                      </div>

                      <div>
                        <h3 className="card-title fs-1 fw-bold exBold" style={{ margin: 0 }}>{userDashBoardsData?.userDataPayload?.totalBookingPaceData}</h3>
                        <p className="mt-4" style={{ fontSize: '16px', color: '#202744' }}>Booking Pace Momentum</p>
                      </div>

                    </div>
                  </div>
                </div>

              </div>

              <div className="row">
                <h5 className="exDemiBold fs-22 " style={{ padding: "20px", margin: "unset", color: "#000" }}>Top Keywords</h5>

                <div className="buttonplus">
                  <button class="btn btn-light dash_card card d-inline-block">hideaway &nbsp; <i className="pi pi-plus" style={{ fontSize: '1rem' }}> </i></button>
                  <button class="btn btn-light dash_card card d-inline-block">boho-chic decor &nbsp; <i className="pi pi-plus" style={{ fontSize: '1rem' }}> </i></button>
                  <button class="btn btn-light dash_card card d-inline-block">nook &nbsp; <i className="pi pi-plus" style={{ fontSize: '1rem' }}> </i></button>
                  <button class="btn btn-light dash_card card d-inline-block">studio &nbsp; <i className="pi pi-plus" style={{ fontSize: '1rem' }}> </i></button>
                  <button class="btn btn-light dash_card card d-inline-block">hideaway &nbsp; <i className="pi pi-plus" style={{ fontSize: '1rem' }}> </i></button>
                  <button class="btn btn-light dash_card card d-inline-block">boho-chic decor &nbsp; <i className="pi pi-plus" style={{ fontSize: '1rem' }}> </i></button>
                  <button class="btn btn-light dash_card card d-inline-block">nook &nbsp; <i className="pi pi-plus" style={{ fontSize: '1rem' }}> </i></button>
                  <button class="btn btn-light dash_card card d-inline-block">studio &nbsp; <i className="pi pi-plus" style={{ fontSize: '1rem' }}> </i></button>
                  <button class="btn btn-light dash_card card d-inline-block">hideaway &nbsp; <i className="pi pi-plus" style={{ fontSize: '1rem' }}> </i></button>
                  <button class="btn btn-light dash_card card d-inline-block">boho-chic decor &nbsp; <i className="pi pi-plus" style={{ fontSize: '1rem' }}> </i></button>
                  <button class="btn btn-light dash_card card d-inline-block">nook &nbsp; <i className="pi pi-plus" style={{ fontSize: '1rem' }}> </i></button>
                  <button class="btn btn-light dash_card card d-inline-block">studio &nbsp; <i className="pi pi-plus" style={{ fontSize: '1rem' }}> </i></button>
                  <button class="btn btn-light dash_card card d-inline-block">hideaway &nbsp; <i className="pi pi-plus" style={{ fontSize: '1rem' }}> </i></button>
                  <button class="btn btn-light dash_card card d-inline-block">boho-chic decor &nbsp; <i className="pi pi-plus" style={{ fontSize: '1rem' }}> </i></button>
                  <button class="btn btn-light dash_card card d-inline-block">nook &nbsp; <i className="pi pi-plus" style={{ fontSize: '1rem' }}> </i></button>
                  <button class="btn btn-light dash_card card d-inline-block">studio &nbsp; <i className="pi pi-plus" style={{ fontSize: '1rem' }}> </i></button>
                </div>
              </div>

            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Dashboards;
