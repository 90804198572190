import React, { useState } from "react";
// import Header from "../../Layouts/HeaderPage";
// import SideBar from "../../Layouts/SideBar";
import { Form, ErrorMessage, Field, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import TextError from "../../Components/TextError";
import { GuestyAPISchema } from "../../Utils/Validation/Validation";
import { GenrateAccessTokenForGuesty } from "../../Redux/Features/authReducer/authSlice";
import { useNavigate } from "react-router-dom";
import { CONNECTION, LISTING } from "../../Routes/Constant";
import { Dialog } from "primereact/dialog";
// import { BreadCrumb } from "primereact/breadcrumb";
import { setFirstTimeOpen } from "../../Redux/Features/listReducer/listSlice";
import { getUser } from "../../Redux/Features/LocalStorage";
import { setGuestyModalVisible } from "../../Redux/Features/stateReducer/stateSlice";


function Guesty() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // State for loading indicator

  const userData = getUser();

  const InitialValues = {
    guestyAccount_secret: userData?.guestyIntegrationToken || "",
  };

  const [fieldType, setFieldType] = useState({
    key: "password",
    token: "password",
  });

  const { CheckHoatawayConnectionData } = useSelector((state) => state.Auth);
  const { guestyModalVisible } = useSelector((state)=>state.State);

  function handleFieldFocus(value, value2) {

    if ("in" === value && "key" === value2) {
      setFieldType({ key: "text", token: "password" });
    } else if ("in" === value && "token" === value2) {
      setFieldType({ key: "passwprd", token: "text" });
    } else {
      setFieldType({ key: "password", token: "password" });
    }
  }

  const onSubmit = async (values) => {
    let timer;
    setLoading(true); // Start loading indicator
    const data = {
      integrationToken: values.guestyAccount_secret,
    };
    try {
      await dispatch(GenrateAccessTokenForGuesty(data))
        .unwrap()
        .then((result) => {
          if (result?.success) {
           timer = setTimeout(() => {
              navigate(LISTING);
              dispatch(setFirstTimeOpen(true));
              dispatch(setGuestyModalVisible(false));
              clearTimeout(timer);
            }, 1000)
          }
        })
        .finally(() => {
          setLoading(false); // Stop loading indicator
        });
    }
    catch (error) {
      setLoading(false);
    };
  };

  // const items = [
  //   { label: "Connection", url: CONNECTION },
  //   { label: "Guesty" },
  // ];
  // const home = { icon: "pi pi-home", url: "/" };

  const guestyHeader = (
    <>
      <h4 className="color-black exMedium mb-3">Connect to Guesty Account</h4>
    </>
  );

  return (
    <>
     <Dialog
        className="p-4 background-white rounded-17 mx-3 listing-setting"
        header={guestyHeader}
        visible={guestyModalVisible}
        style={{ maxWidth: "550px", width: "100%" }}
        onHide={() => {
          if (!guestyModalVisible) return;
          dispatch(setGuestyModalVisible(false));
        }}
      >
      <div id="hostaway_connection">
        {/* <Header /> */}
        <div id="wrapper">
          {/* <SideBar /> */}
          {/* <section id="content-wrapper"> */}
            {/* <div className=" border-bottom pb-2">
              <BreadCrumb
                model={items}
                home={home}
                className="border-0 text-decoration-none mb-2"
              />
              <div className="welcome-user">
                <h2 className=" content-title exDemiBold">
                  Connect To Guesty Account
                </h2>
              </div>
            </div> */}
            <Formik
              initialValues={InitialValues}
              validationSchema={GuestyAPISchema}
              onSubmit={onSubmit}
            >
              <Form>
                <div className="row py-4 mx-2 aligndiv">
                  <div className="col-md-6 px-0">
                    <label for="hostawayAccount_secret" className="exMedium">
                      Integration Token <span className="strictly">*</span>
                    </label>
                  </div>
                  <div className="col-md-6 px-0">
                    <Field
                      type={fieldType.token}
                      className="w-100 form-control form-control-lg input-lg rounded-pill color-dark "
                      id="guestyAccount_secret"
                      placeholder="Enter Integration Token"
                      name="guestyAccount_secret"
                      onFocus={(e) => {
                        handleFieldFocus("in", "token");
                      }}
                      onBlur={(e) => {
                        handleFieldFocus("out", "key");
                      }}
                    />
                    <ErrorMessage name="guestyAccount_secret" component={TextError}
                    />
                  </div>
                </div>
                {CheckHoatawayConnectionData &&
                  CheckHoatawayConnectionData?.status &&
                  CheckHoatawayConnectionData?.validConnection?.find(item => item?.dataSource === 'guesty')
                  ? (
                    <div className="login-card-bottom mt-4 text-end">
                      <button
                        type="submit"
                        className="btn btn-lg button-purple rounded-pill ms-auto mt-2"
                        onClick={() => navigate("/admin/dashboard")}
                      >
                        Go back to Dashboard
                      </button>
                    </div>
                  ) : (
                    <div className="login-card-bottom mt-4 text-end">
                      <button
                        type="submit"
                        className="btn btn-lg button-purple rounded-pill ms-auto mt-2"
                        disabled={loading} // Disable button while loading
                      >
                        {loading ? (
                          <span
                            className="spinner-border spinner-border-sm me-2 text-dark"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Connect Guesty"
                        )}
                      </button>
                    </div>
                  )}
              </Form>
            </Formik>
          {/* </section> */}
        </div>
      </div>
      </Dialog> 
    </>
  );
}

export default Guesty;
