import React, { useEffect } from "react";
import {
  getPaymentId,
  removePaymentId,
} from "../../Redux/Features/LocalStorage";
import { useDispatch } from "react-redux";
import { checkPaymentAboutPaymentId } from "../../Redux/Features/authReducer/authSlice";
import SideBar from "../../Layouts/SideBar";
import Header from "../../Layouts/HeaderPage";

const PaymentRejected = () => {
  const dispatch = useDispatch();

  const localPaymentId = getPaymentId();

  useEffect(() => {
    if (localPaymentId) {
      dispatch(checkPaymentAboutPaymentId({ paymentId: localPaymentId }))
        .unwrap()
        .then((result) => {
          removePaymentId();
        });
    }
  }, []);

  return (
    <>
      <div id="payment_failed">
        <div id="wrapper">
          <SideBar />
          <Header />
          <section id="content-wrapper" className="vh-100">
            <div class="bg_404">
              <div class="msg_404  text-center mb-4 ">
                <div className="success_bg color-red  m-auto mb-5 position-relative">
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3249_16739)">
                      <path
                        d="M37 0H11C4.934 0 0 4.934 0 11V13C0 14.902 0.764 16.63 2 17.894V37C2 43.066 6.934 48 13 48H17C18.658 48 20 46.658 20 45C20 43.342 18.658 42 17 42H13C10.244 42 8 39.756 8 37V20H41C44.86 20 48 16.86 48 13V11C48 4.934 43.066 0 37 0ZM42 13C42 13.552 41.552 14 41 14H7C6.448 14 6 13.552 6 13V11C6 8.244 8.244 6 11 6H37C39.756 6 42 8.244 42 11V13Z"
                        fill="white"
                      />
                      <g clip-path="url(#clip1_3249_16739)">
                        <path
                          d="M36 23.5C29.383 23.5 24 28.883 24 35.5C24 42.117 29.383 47.5 36 47.5C42.617 47.5 48 42.117 48 35.5C48 28.883 42.617 23.5 36 23.5ZM39.707 37.793C40.098 38.184 40.098 38.816 39.707 39.207C39.512 39.402 39.256 39.5 39 39.5C38.744 39.5 38.488 39.402 38.293 39.207L36 36.914L33.707 39.207C33.512 39.402 33.256 39.5 33 39.5C32.744 39.5 32.488 39.402 32.293 39.207C31.902 38.816 31.902 38.184 32.293 37.793L34.586 35.5L32.293 33.207C31.902 32.816 31.902 32.184 32.293 31.793C32.684 31.402 33.316 31.402 33.707 31.793L36 34.086L38.293 31.793C38.684 31.402 39.316 31.402 39.707 31.793C40.098 32.184 40.098 32.816 39.707 33.207L37.414 35.5L39.707 37.793Z"
                          fill="white"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_3249_16739">
                        <rect width="48" height="48" fill="white" />
                      </clipPath>
                      <clipPath id="clip1_3249_16739">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="translate(24 23.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <p class="exDemiBold display-5 text-red mb-4">
                  Payment Failed!
                </p>
                <h4 class="exMedium mb-4">
                  Unfortunately payment was rejected.
                </h4>
                <p className="lh-lg w-75 m-auto">
                  You will be redirected to home page shortly or click here to
                  return to home page.
                </p>
                <button
                  class="btn btn-lg button-navy rounded-pill mt-5"
                  label="Link"
                  link
                  onClick={() => window.open("/", "_self")}
                >
                  <svg
                    className="me-2"
                    width="28"
                    height="28"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_319_15512"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="28"
                      height="28"
                    >
                      <rect
                        x="20"
                        y="20.5"
                        width="20"
                        height="20"
                        transform="rotate(-180 20 20.5)"
                        fill="white"
                      />
                    </mask>
                    <g mask="url(#mask0_319_15512)">
                      <path
                        d="M8.34211 6.75L9.24211 7.91667L7.91579 9.66667H17.5V11.3333L7.91579 11.3333L9.22632 13.0833L8.32632 14.25L5.5 10.5L8.34211 6.75Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                  Back to Home
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
export default PaymentRejected;
