import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setEmail, setLoggedIn, setUser } from "../../Redux/Features/authReducer/authSlice";
import { RESET_PASSWORD, SIGN_IN } from "../../Routes/Constant";
import { changePassword, forgotPassword, login, logout, register, registerOtp, resetPassword, updateProfile } from "../../services/api/auth/mutations";
import { errorToast, successSwal, successToast } from "../../Utils/constant/commonFunction";
import { useCheckAndNavigate } from "../checks/check-connection";

export const useForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (data) => forgotPassword(data),
    onSuccess: async (response, data) => {
      await new Promise(resolve => {
        const toastId = successToast(response?.message);
        toast.onChange(payload => {
          if (payload.id === toastId &&
            (payload.status === 'removed' || payload.status === 'dismissed')) {
            resolve();
          }
        });
      });
      dispatch(setEmail(data.email));
      navigate(RESET_PASSWORD);
    },
    onError: async (error) => {
      setTimeout(() => {
        errorToast(error.response?.data?.message);
      });
    },
  })
};

export const useResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (data) => resetPassword(data),
    onSuccess: async () => {
      await successSwal("Reset password successfully");
      dispatch(setEmail(null));
      navigate(SIGN_IN);
    },
    onError: (error) => {
      setTimeout(() => {
        errorToast(error.response?.data?.message);
      });
    },
  });
}

export const useRegisterOtp = () => {
  return useMutation({
    mutationFn: (data) => registerOtp(data),
    onSuccess: (response) => {
      successToast(response?.message);
    },
    onError: (error) => {
      setTimeout(() => {
        errorToast(error.response?.data?.message);
      });
    },
  });
}

export const useUpdateProfile = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => updateProfile(data),
    onSuccess: (response) => {
      queryClient.invalidateQueries("userData");
      successToast(response?.message);
    },
    onError: (error) => {
      setTimeout(() => {
        errorToast(error.response?.data?.message);
      });
    },
  });
}

export const useChangePassword = () => {
  return useMutation({
    mutationFn: (data) => changePassword(data),
    onSuccess: (response) => {
      successToast(response?.message);
    },
    onError: (error) => {
      setTimeout(() => {
        errorToast(error.response?.data?.message);
      });
    },
  });
}

export const useRegister = (setButtonVisible, setVisible) => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: (data) => register(data),
    onSuccess: async () => {
      setButtonVisible(false);
      setVisible(false);
      await successSwal("Registration successful");
      navigate(SIGN_IN);
    },
    onError: () => {
      setButtonVisible(false);
    },
  });
}

export const useLogout = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: () => logout(),
    onSettled: () => {
      dispatch(setLoggedIn(false));
    },
    onError: (error) => {
      setTimeout(() => {
        errorToast(error.response?.data?.message);
      });
    },
  });
}

export const useLogin = () => {
  const checkAndNavigate = useCheckAndNavigate();
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (data) => login(data),
    onSuccess: async (response) => {
      await successSwal("User logged in successfully");
      if (process.env.REACT_APP_AMPLITUDE) window.amplitude.setUserId(`uid_${response?.result?.userId}`);
      dispatch(setUser({ ...response?.result }));
      dispatch(setLoggedIn(true));
      checkAndNavigate();
    },
    onError: (error) => {
      setTimeout(() => {
        errorToast(error.response?.data?.message);
      });
    },
  })
};