import axios from "axios";
import { getToken } from "../LocalStorage";
import { HeaderToken } from "../../../Utils/constant/commonFunction";
import { 
    BASE_URL, 
    WEB_NOTIFICATION_ACTIVATE,
    EMAIL_NOTIFICATION_ACTIVATE,
    FETCH_NOTIFICATION,
    MARK_ALL_READ,
    GET_NOTIFICATION_INACTIVE_BY_USER
} from "../../../Config/Config"

// activate web notification API
const webNotificationActivateApi = async (data) => {
  const response = await axios.post(
    BASE_URL + WEB_NOTIFICATION_ACTIVATE,
    data,
    HeaderToken(getToken())
  );
  return response.data;
};

// activate email notification API
const emailNotificationActivateApi = async (data) => {
  const response = await axios.post(
    BASE_URL + EMAIL_NOTIFICATION_ACTIVATE,
    data,
    HeaderToken(getToken())
  );
  return response.data;
};

// fetch notification API
const fetchNotificationApi = async () => {
  const response = await axios.get(
    BASE_URL + FETCH_NOTIFICATION,
    HeaderToken(getToken())
  );
  return response.data;
};

// mark all notification read API
const markAllReadApi = async (data) => {
  const response = await axios.post(
    BASE_URL + MARK_ALL_READ,
    data,
    HeaderToken(getToken())
  );
  return response.data;
};

// manual delete all notification read API
const getNotificationInactiveByUser = async (data) => {
  const response = await axios.post(
    BASE_URL + GET_NOTIFICATION_INACTIVE_BY_USER,
    data,
    HeaderToken( getToken())
  );
  return response.data;
};

  const notificationService = {
    webNotificationActivateApi,
    emailNotificationActivateApi,
    fetchNotificationApi,
    markAllReadApi,
    getNotificationInactiveByUser
  }
  export default notificationService;